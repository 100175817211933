import AppPublic from "../AppPublic";
import { Box, FormControl, InputAdornment, Paper, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import authApi from "../../../app/services/auth";

import { Email, Login as LogIcon, LoginRounded, Password } from "@mui/icons-material";
import SimpleForm from "../../../assets/utils/SimpleForm";
import { LoginData } from "../../../app/types/auth";
import { LoadingButton } from "../../secured/Misc/Misc";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Urls from "../../../app/appUrls";

const Login = () => {
  return <AppPublic
    actions={<Box display={"flex"} justifyContent={"space-between"} mt={1}>
      <Link to={Urls.register}>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"register"} />
        </Typography>
      </Link>
      <Link to={Urls.forgot}>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"forgotten-password"}/>
        </Typography>
      </Link>
    </Box>}
  >
    <Box
      sx={{
        display:"flex",
        flexDirection:"column",
        height:'100%',
        justifyContent: "space-between"
      }}
    >
      <Typography variant={"h6"} sx={{ fontWeight: "bold" }} mb={1}>
        👋 <FormattedMessage id={"welcome-back"}/>
      </Typography>
      <Typography variant={"subtitle2"} color={"text.secondary"}>
        <FormattedMessage id={"login-to-continue"}/>
      </Typography>
      <LoginForm/>
    </Box>

  </AppPublic>
}

const LoginForm = () => {
  const [ log, logRes ] = authApi.useLoginMutation();
  return <SimpleForm onSubmit={(s) => log(s as LoginData)}>
    <FormControl margin={"dense"} fullWidth sx={{mt: 2}}>
      <TextField
        name={"email"}
        type={"email"}
        label={"Email"}
        required
        margin={"dense"}
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <Email/>
          </InputAdornment>
        }}
      />
    </FormControl>
    <FormControl margin={"dense"} fullWidth>
      <TextField
        name={"password"}
        type={"password"}
        label={<FormattedMessage id={"password"}/>}
        margin={"dense"}
        required
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <Password/>
          </InputAdornment>
        }}
      />
    </FormControl>

    <Box mt={3}>
      <LoadingButton
        fullWidth
        type={'submit'}
        size={'large'}
        data={logRes}
        variant={"contained"}
        sx={{
          py: 2
        }}
      >
        <FormattedMessage id={"login"}/>
      </LoadingButton>
    </Box>
  </SimpleForm>
}

export default Login;
