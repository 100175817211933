import Box from "@mui/material/Box";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import { Autocomplete, FormControl, FormControlLabel, InputAdornment, Switch, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { MuiTelInput } from "mui-tel-input";
import MenuItem from "@mui/material/MenuItem";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import React, { useEffect, useMemo, useState } from "react";
import { StepProps } from "./Onboarding";
import { EntityType, OnboardingData } from "../../../../app/services/profile";
import globalApi from "../../../../app/services/globalApi";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2";

const BusinessInfo = (p: StepProps) => {

  const user = useAppSelector(getUser);
  const [ phone, setPhone ] = useState("");
  const [ country, setCountry ] = useState(p.data.address?.country ?? "FR");
  const { data: countries } = globalApi.useGetCountriesQuery();
  const [ entityType, setEntityType ] = useState(p.data.entity_type ?? EntityType.Company);
  const [ hasVat, setHasVat ] = useState(true);

  const options = countries?.map(c => {
    return {
      label: c.name,
      value: c.code,
    }
  });

  const selectedCountry = useMemo(() => countries?.find(c => c.code === country), [ country ]);

  useEffect(() => {
    if (user && user.address?.phone_number) {
      setPhone(user.address.phone_number);
    } else if (p.data.address?.phone_number) {
      setPhone(p.data.address?.phone_number);
    }
  }, [ user?.address?.phone_number, p.data.address?.phone_number ]);

  useEffect(() => {
    entityType === EntityType.Company && setHasVat(true);
  }, [ entityType ]);

  return (
    <Box key={`data-${p.data.name}`}>
      <SimpleForm
        onSubmit={(s) =>
          p.onSubmit({
            ...s,
            entity_type: parseInt(s.entity_type),
            address: { ...s.address, vat_exempted: !hasVat }
          } as Partial<OnboardingData>)}
      >
        <Grid2 container spacing={2}>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                size={"small"}
                name="entity_type"
                label={<FormattedMessage id="company-type"/>}
                select
                variant="standard"
                required
                value={entityType}
                onChange={(e) => setEntityType(e.target.value as unknown as EntityType)}
              >
                <MenuItem value={EntityType.Company}><FormattedMessage id={"company"}/></MenuItem>
                <MenuItem value={EntityType.Independent}><FormattedMessage id={"independent"}/></MenuItem>
              </TextField>
            </FormControl>
          </Grid2>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name="name"
                size={"small"}
                label={<FormattedMessage id="company-name"/>}
                variant="standard"
                required
                defaultValue={p.data.name}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name="address[siret]"
                size={"small"}
                label={<FormattedMessage id="siren"/>}
                variant="standard"
                required
                defaultValue={p.data.address?.siret}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name="address[address]"
                size={"small"}
                label={<FormattedMessage id="address"/>}
                variant="standard"
                required
                defaultValue={p.data.address?.address}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name="address[city]"
                size={"small"}
                label={<FormattedMessage id="city"/>}
                variant="standard"
                required
                defaultValue={p.data.address?.city}
              />
            </FormControl>
          </Grid2>
          {options && (
            <Grid2 xs={p.size === "small" ? 6 : 12}>
              <input type={"hidden"} name={"address[country]"} value={country} />
              <FormControl margin="normal" fullWidth>
                <Autocomplete
                  value={options.find(o => o.value === country)}
                  renderInput={(params) =>
                    <TextField {...params} label={<FormattedMessage id="country"/>} variant={"standard"}/>}
                  options={options}
                  onChange={(event, value) => value && setCountry(value?.value)}
                />
              </FormControl>
            </Grid2>
          )}
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                name="address[zip]"
                size={"small"}
                label={<FormattedMessage id="zip"/>}
                variant="standard"
                required
                defaultValue={p.data.address?.zip}
              />
            </FormControl>
          </Grid2>
          <input type={"hidden"} name={"address[vat_exempted]"} value={!hasVat ? "1" : "0"}/>
          {selectedCountry?.zone === "eu" && (
            <Grid2 xs={p.size === "small" ? 6 : 12}>
              <FormControl margin="normal" fullWidth>
                <TextField
                  name="address[vat_number]"
                  size={"small"}
                  label={<FormattedMessage id="vat-number"/>}
                  variant="standard"
                  required
                  defaultValue={p.data.address?.vat_number}
                  disabled={!hasVat}
                  InputProps={{
                    endAdornment: entityType === EntityType.Independent && (<InputAdornment position={"end"}>
                      <FormControlLabel
                        control={<Switch checked={hasVat} onChange={(e) => setHasVat(e.target.checked)}
                                         size={"small"}/>}
                        label={<Typography variant={"body2"}><FormattedMessage id={"has-tva"}/></Typography>}/>
                    </InputAdornment>)
                  }}
                />
              </FormControl>
            </Grid2>
          )}
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <TextField
                key={`e-${user?.email}`}
                name="address[invoice_email]"
                size={"small"}
                label={<FormattedMessage id="invoice-email"/>}
                variant="standard"
                required
                defaultValue={p.data.address?.invoice_email ?? user?.email}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={p.size === "small" ? 6 : 12}>
            <FormControl margin="normal" fullWidth>
              <MuiTelInput
                name={"address[phone_number]"}
                size={"small"}
                defaultCountry="FR"
                variant="standard"
                value={phone}
                onChange={(value) => setPhone(value)}
                required
              />
            </FormControl>
          </Grid2>
          {p.nav}
        </Grid2>
      </SimpleForm>
    </Box>
  );
};

export default BusinessInfo;
