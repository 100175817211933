import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import authApi from "../services/auth";

import { StorageManager } from "../storage";
import Urls from "../appUrls";
import profileApi, { Me, Profile } from "../services/profile";
import { RootState } from "../store";
import { ChartAggregations, ReportingParams } from "../services/globalApi";
import dayjs from "dayjs";

const initialState: AppSlice = {
  redirect: null,
  user: null,
  reporting: {
    params: {
      from: dayjs('2023-01-01').format("YYYY-MM-DD"),
      to: dayjs('2023-03-31').format("YYYY-MM-DD"),
      type: 'all',
      period: ChartAggregations.Weekly
    },
  }
}

const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    redirect: (state, action: PayloadAction<string>) => {
      state.redirect = action.payload;
    },
    clearRedirect: (state) => {
      state.redirect = null;
    },
    setReportingParams: (state, action: PayloadAction<ReportingParams>) => {
      state.reporting.params = action.payload;
    },
    // clearRedirectTab: (state) => {
    //   state.redirectTab = null;
    // },
  },
  extraReducers: builder => {
    builder
    .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      StorageManager.set(action.payload.access_token);
      window.location.href = Urls.app.dash;
    })
    .addMatcher(profileApi.endpoints.logout.matchFulfilled, (state, action) => {
      StorageManager.clear();
      window.location.href = Urls.login;
    })
    .addMatcher(profileApi.endpoints.getMe.matchFulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    })
    .addMatcher(profileApi.endpoints.getProfile.matchFulfilled, (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    })
  }
});

export const
  {
    setReportingParams,
    redirect,
    clearRedirect,
  } = app.actions;

/** Types */
interface AppSlice {
  redirect: string | null,
  user: Partial<Me & Profile> | null,
  reporting: {
    params: ReportingParams,
  }
}

export type ReportingView = "table" | "chart";

/** Getters */
export const getUser = (state: RootState) => state.app.user;
export const getReportingParams = (state: RootState) => state.app.reporting.params;
export const getRedirectUrl = (state: RootState): string | null => state.app.redirect;

export default app.reducer;
