import { RtkApiRes } from "../../../app/types/misc";
import Button, { ButtonProps } from "@mui/material/Button";
import { CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import ReactGA from "react-ga4";

type LoadingButtonProps = {
  data: RtkApiRes<any>,
  children: any,
} & ButtonProps

export const LoadingButton = (p: LoadingButtonProps) => {
  const { data, children, ...props } = p;
  return <Button
    disabled={data.isLoading}
    {...props}
  >
    {data.isLoading
      ? <>
        <CircularProgress size={"1rem"}/>
        <FormattedMessage id={"please-wait"}/>
      </>
      : children
    }
  </Button>
}

type LocaleProps = {
  locale: string,
  className?: string
}

export const LocaleFlag = (props: LocaleProps) => {

  const parts: Array<string> = props.locale.split("_")
  .map(p => p.toLowerCase())
  .reduce((prev: Array<string>, curr: string) => {
      if ("en" === curr) {
        curr = "gb";
      }

      if (-1 === prev.indexOf(curr)) {
        prev.push(curr);
      }

      return prev;
    },
    []);

  return <Box className={props.className} title={props.locale} mx={1} >
		<span key="language" className={`fi fi-${parts[0]}`}/>
    {
      parts.length > 1 && <span key="country" className={`fi fi-${parts[1]}`}/>
    }
	</Box>;
};

export const niceUrl = (url: string) => {
  return url?.replace(/^https?:\/\/|\/$/g, '').replace(/^HTTPS?:\/\/|HTTP?:\/\//g, '');
}

type LoadingBlockProps = {
  children: any,
  data: RtkApiRes<any>,
}
export const LoadingBlock = (p: LoadingBlockProps) => {
  return <Box>
    {p.data.isLoading
      ? <CircularProgress />
      : p.data.isSuccess && p.children}
  </Box>
}

export const pushGAEvent = (event: "signup-partner") => ReactGA.event(event);
