import { clearRedirect, getRedirectUrl } from "../../app/slices/app";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks/hooks";

const AppRedirect = () => {
  const redirect = useAppSelector(getRedirectUrl);
  const dispatch = useAppDispatch();
  useEffect(() => {
    redirect && dispatch(clearRedirect());
  }, [ redirect, dispatch ]);
  return redirect ? <Navigate to={redirect}/> : null;
}

export default AppRedirect;
