import { StorageManager } from "./storage";

export type CustomHeaders = Record<string, string>;

const baseHeaders: CustomHeaders = {
  "X-Requested-With": "XMLHttpRequest",
  "Feedcast-Client": "customer",
};

export const getCustomHeaders = (headers: Headers, withToken: boolean): Headers => {
  Object.keys(baseHeaders).forEach((key) => {
    headers.set(key, baseHeaders[key]);
  });

  if (withToken) {
    headers.set("Feedcast-Token", StorageManager.get() as string);
  }

  return headers;
}

const ApiUrls = {
  auth: {
    login: '/',
    logout: `/api/logout`,
    forgot: `/forgot-password`,
    refresh: (token: string) => `/refresh-password/${token}.html`,
    register: "/partners/signup",
    validate: (token: string) => `/validate-account/${token}.html`,
  },
  profile: {
    me: '/api/me',
    profile: '/api/me/profile',
    newPassword: '/api/me/password',
    onboarding: '/api/reseller/onboarding',
  },
  global: {
    reporting: '/api/admin/reporting',
    periods: `/api/admin/reporting/periods`,
    countryList: `/api/global-lists/countries`,
  },
  customers: {
    list: `/api/customers`,
    info: (id: number | string): string => `/api/customers/${id}`,
    autologin: (id: number | string): string => `/api/autologin/${id}`,
  },
  feeds: {
    list: `/api/feeds`,
    initSubscription: (feed: string|number): any => `/api/feeds/${feed}/subscription/init`,
    manageSubscription: (feed: string|number): any => `/api/feeds/${feed}/subscription`,
    cancelSubscription: (feed: string|number): any => `/api/feeds/${feed}/subscription/cancel`,
  },
  commissions: {
    list: "/api/commissions",
    billedCommissions: "/api/invoice-calls",
    retrieve: "/api/commissions/retrieve",
  },
  invoices: {
    list: `/api/invoices`, //merchant_id params
    upcoming: `/api/invoices/upcoming`, // for reseller type === payer TODO
    last: '/api/invoices/last', //merchant_id params TODO
  },
  commercials: {
    list: `/api/commercials`,
    edit: (id: number) => `/api/commercials/${id}`,
  },
}

export default ApiUrls;
