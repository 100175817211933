import Box from "@mui/material/Box";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { Business } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import { FormControl, TextField } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import customerApi, { Address, CustomerCommercial, EditCustomerApi } from "../../../../app/services/customerApi";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import { LoadingButton } from "../../Misc/Misc";
import { useNotifications } from "../../../../assets/utils/utils";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import { ResellerTypes } from "../../../../app/services/profile";
import globalApi from "../../../../app/services/globalApi";
import MenuItem from "@mui/material/MenuItem";

type CustomerAddressProps = {
  address: Partial<Address & {
    name: string,
    id: number,
    custom_id: string,
    commercial: CustomerCommercial | null,
  }>;
}

const CustomerAddress = (p: CustomerAddressProps) => {

  const { address } = p;

  const countries = globalApi.useGetCountriesQuery();

  const [ edit, editRes ] = customerApi.useEditCustomerMutation();
  useNotifications(editRes, "success-edit-customer");

  const me = useAppSelector(getUser);

  const [ getCommercials, commercialsQuery ] = globalApi.useLazyGetCommercialsQuery();
  useEffect(() => {
    if (me?.type === ResellerTypes["NON_PAYER"]) {
      getCommercials();
    }
  }, [ me?.type === ResellerTypes["NON_PAYER"] ]);

  return <Box mt={3}>
    <SimpleForm
      onSubmit={(s) => edit(s as EditCustomerApi)}
      key={`key-${address.name?.length}`}
    >
      <input type={"hidden"} name={"id"} value={p.address.id}/>
      <Typography variant={"h5"} sx={{ display: "flex", alignItems: "center" }}>
        <Business sx={{ mr: 1 }}/>
        <FormattedMessage id={"informations"}/>
      </Typography>

      <Paper sx={{ p: 2 }}>
        <Grid2 container spacing={2}>

          <Grid2 xs={me?.type === ResellerTypes["NON_PAYER"] ? 6 : 12}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"customId"}/>}
                name={"custom_id"}
                defaultValue={address.custom_id}
              />
            </FormControl>
          </Grid2>

          {me?.type === ResellerTypes["NON_PAYER"] && (
            <Grid2 xs={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  defaultValue={p.address.commercial?.id}
                  label={<FormattedMessage id={"commercial"}/>}
                  name={"commercial"}
                >
                  {commercialsQuery.data?.map(c => <MenuItem key={`c-${c.id}`} value={c.id}>
                    {c.first_name + " " + c.last_name}
                  </MenuItem>)}
                </TextField>
              </FormControl>
            </Grid2>
          )}

          <Grid2 xs={12}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"company-name"}/>}
                defaultValue={address.name}
                name={"name"}
              />
            </FormControl>
          </Grid2>

          <Grid2 xs={6}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"address"}/>}
                defaultValue={address.address}
                name={"address[address]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={6}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"address2"}/>}
                defaultValue={address.address2}
                name={"address[address2]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>

          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"city"}/>}
                defaultValue={address.city}
                name={"address[city]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"zip"}/>}
                defaultValue={address.zip}
                name={"address[zip]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                select
                label={<FormattedMessage id={"country"}/>}
                defaultValue={address.country}
                name={"address[country]"}
                size={'small'}
              >
                {countries.data?.map(c => <MenuItem key={`country-${c.name}`} value={c.code}>
                  {c.name}
                </MenuItem>)}
              </TextField>
            </FormControl>
          </Grid2>

          {/*<Grid2 xs={6}>*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <TextField*/}
          {/*      label={<FormattedMessage id={"vat-number"}/>}*/}
          {/*      defaultValue={address.vat_number}*/}
          {/*      name={"address[vat_number]"}*/}
          {/*      size={'small'}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</Grid2>*/}
          {/*<Grid2 xs={6}>*/}
          {/*  <FormControl fullWidth>*/}
          {/*    <TextField*/}
          {/*      label={<FormattedMessage id={"vat-type"}/>}*/}
          {/*      defaultValue={address.vat_type}*/}
          {/*      name={"address[vat_type]"}*/}
          {/*      size={'small'}*/}
          {/*    />*/}
          {/*  </FormControl>*/}
          {/*</Grid2>*/}

          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"siret"}/>}
                defaultValue={address.siret}
                name={"address[siret]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"phone-number"}/>}
                defaultValue={address.phone_number}
                name={"address[phone_number]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
          <Grid2 xs={4}>
            <FormControl fullWidth>
              <TextField
                label={<FormattedMessage id={"invoice-email"}/>}
                defaultValue={address.invoice_email}
                name={"address[invoice_email]"}
                size={'small'}
              />
            </FormControl>
          </Grid2>
        </Grid2>

        <Box mt={2} display={"flex"} justifyContent={"end"}>
          <LoadingButton
            type={"submit"}
            variant={"contained"}
            data={editRes}
          >
            <FormattedMessage id={"edit"}/>
          </LoadingButton>
        </Box>
      </Paper>

    </SimpleForm>
  </Box>
}

export default CustomerAddress;
