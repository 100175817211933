import AppSecured from "../../AppSecured";
import { FormattedMessage } from "react-intl";
import customerApi, { CreateCustomerBody, Customer } from "../../../../app/services/customerApi";
import FTable from "../../Misc/FTable";
import Grid2 from "@mui/material/Unstable_Grid2";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { AccountBox, Add, DataObject, Edit, PersonAdd } from "@mui/icons-material";
import {
  formatToLocaleDate,
  getFeedSubStatus,
  SubStatusColor,
  useBoolean,
  useNotifications
} from "../../../../assets/utils/utils";
import { Chip, Dialog, DialogContent, DialogTitle, Fab, FormControl, Stack, TextField } from "@mui/material";
import Vue from "../../Misc/Vue";
import { ResellerTypes } from "../../../../app/services/profile";
import AutologinPopover from "../AutologinPopover";
import { useEffect, useState } from "react";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import Button from "@mui/material/Button";
import { LoadingButton } from "../../Misc/Misc";
import { Link } from "react-router-dom";
import Urls from "../../../../app/appUrls";
import { useData } from "../../../../app/hooks/useData";
import Paper from "@mui/material/Paper";

const Customers = () => {
  const [ open, { setTrue, setFalse } ] = useBoolean();
  return <AppSecured>
    <Box display={"flex"} justifyContent={"space-between"}>
      {/*<Typography variant={"h4"} display={"flex"} alignItems={"center"}>*/}
      {/*  <AccountBox fontSize={"large"} sx={{ mr: 1 }}/>*/}
      {/*  <FormattedMessage id={"your-customers"}/>*/}
      {/*</Typography>*/}
      <Box />
      <Box>
        <Fab
          variant={"extended"}
          color={"primary"}
          sx={{ boxShadow: "none" }}
          onClick={setTrue}
        >
          <Add sx={{ mr: 1 }}/>
          <FormattedMessage id={"create-customer"}/>
        </Fab>
      </Box>
    </Box>


    <Box mt={2}>
      <Paper sx={{ overflow: "hidden" }}>
        <CustomersTable/>
      </Paper>

    </Box>

    <CreateCustomerDialog open={open} handleClose={setFalse}/>
  </AppSecured>
}

const CustomersTable = () => {
  const [ { customers } ] = useData();
  return <>
    <FTable
      size={'small'}
      head={<>
        <TableCell>Client</TableCell>
        <TableCell>Date d'inscription</TableCell>
        <TableCell/>
      </>}
    >
      {customers.slice().sort((a, b) => b.created - a.created).map(c => <ClientRow key={`c-${c.id}`} row={c}/>)}
    </FTable>
  </>
}

function ClientRow(props: { row: Customer }) {
  const { row } = props;
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          {row.name}
        </TableCell>
        <TableCell>
          {formatToLocaleDate(row.created, true)}
        </TableCell>
        <TableCell>
          <Stack direction={"row"} display={"flex"} justifyContent={"end"}>
            <IconButton
              size={'small'}
              component={Link}
              to={Urls.app.customerEdit(row.id)}
            >
              <Edit fontSize={'small'}/>
            </IconButton>
            <AutologinPopover merchant={row.id}/>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

type CreateCustomerDialogProps = {
  open: boolean,
  handleClose: () => void,
}

function CreateCustomerDialog(p: CreateCustomerDialogProps) {
  const { open, handleClose } = p;

  const [ createCustomer, createCustomerRes ] = customerApi.useCreateCustomerMutation();
  useNotifications(createCustomerRes, "success-customer-creation");
  useEffect(() => {
    createCustomerRes.isSuccess && handleClose();
  }, [ createCustomerRes.isSuccess ]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle display={"flex"} alignItems={"center"}>
        <PersonAdd/>
        <Typography variant={'h6'} ml={1}><FormattedMessage id={"new-customer"}/></Typography>
      </DialogTitle>
      <DialogContent>
        <SimpleForm onSubmit={(s) => createCustomer(s as CreateCustomerBody)}>
          <FormControl fullWidth>
            <TextField
              size={'small'}
              label={<FormattedMessage id={"company-name"}/>}
              name={"merchant[name]"}
              required
            />
          </FormControl>
          <Grid2 container spacing={1} my={1}>
            <Grid2 xs>
              <FormControl fullWidth>
                <TextField
                  size={'small'}
                  label={<FormattedMessage id={"first-name"}/>}
                  name={"first_name"}
                  required
                />
              </FormControl>
            </Grid2>
            <Grid2 xs>
              <FormControl fullWidth>
                <TextField
                  size={'small'}
                  label={<FormattedMessage id={"last-name"}/>}
                  name={"last_name"}
                  required
                />
              </FormControl>
            </Grid2>
          </Grid2>
          <FormControl fullWidth>
            <TextField
              size={'small'}
              label={"Email"}
              name={"authentication[email]"}
              required
            />
          </FormControl>
          <Box display={"flex"} justifyContent={"end"} mt={1}>
            <LoadingButton
              type={"submit"}
              data={createCustomerRes}
            >
              <FormattedMessage id={"create"}/>
            </LoadingButton>
          </Box>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

export default Customers;
