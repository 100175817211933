import securedApi from "./securedApi";
import ApiUrls from "../apiUrls";
import { ApiSuccess } from "../types/misc";
import { PayerUpcomingInvoice } from "./profile";

const invoiceApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getCommissions: build.query<Commission[], void>({
      query: () => ApiUrls.commissions.list,
    }),
    getBilledCommissions: build.query<BilledCommission[], void>({
      query: () => ApiUrls.commissions.billedCommissions,
    }),
    claimCommissions: build.mutation<ApiSuccess, void>({
      query: () => ({
        url: ApiUrls.commissions.retrieve,
        method: 'POST',
      }),
    }),
    getPayerUpcoming: build.query<PayerUpcomingInvoice, void>({
      query: () => ApiUrls.invoices.upcoming,
    }),
    getPayerInvoices: build.query<StripeInvoice[], void>({
      query: () => ApiUrls.invoices.list,
    }),
    getCustomerInvoices: build.query<StripeInvoice[], string | number>({
      query: (merchant_id) => ({
        url: ApiUrls.invoices.list,
        params: {
          merchant_id,
        }
      }),
    }),
    getCustomerCurrentSub: build.query<StripeInvoice[], string | number>({
      query: (merchant_id) => ({
        url: ApiUrls.invoices.last,
        params: {
          merchant_id
        }
      })
    }),
  })
})

export default invoiceApi;

/** Types */
export type Commission = {
  date: number,
  type: CommissionType,
  invoice: {
    code: string,
    amount: number,
    subscription: null | {
      date_end_period: number,
    }
  }
  amount: number,
  feed: null | {
    name: string,
  }
}

export enum CommissionType {
  "Welcome commission" = 1,
  "Invoice commission"
}

export type BilledCommission = {
  id: number,
  code: string,
  amount: number,
  tax: number,
  created: number,
  status: InvoiceCallStatus
  commissions: {
    amount: number,
    type: CommissionType,
  }[],
}

export enum InvoiceCallStatus {
  Pending = 1,
  Payed
}

export type StripeInvoice = {
  code: string,
  amount: number,
  currency: {
    code: string,
  },
  created: number,
  status: "paid" | "open",
  url: string,
}
