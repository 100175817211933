import AppPublic from "../AppPublic";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import SimpleForm, { SimpleFormData } from "../../../assets/utils/SimpleForm";
import { Fade, FormControl, InputAdornment, TextField } from "@mui/material";
import { AccountBox, Email, Password } from "@mui/icons-material";
import authApi, { RegisterBody } from "../../../app/services/auth";
import { useEffect, useState } from "react";
import { LoadingButton } from "../../secured/Misc/Misc";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MuiTelInput } from "mui-tel-input";
import Urls from "../../../app/appUrls";
import { Link } from "react-router-dom";

const Register = () => {
  const [ step, setStep ] = useState(0);
  const onSignupSuccess = () => setStep(1);
  return <AppPublic
    actions={<Box display={"flex"} justifyContent={"end"} mt={1}>
      <Link to={Urls.login}>
        <Typography variant={"body2"} color={"text.secondary"}>
          <FormattedMessage id={"login"}/>
        </Typography>
      </Link>
    </Box>}
  >
    {step === 0 && (<Box>
      <Typography variant={"h6"}>
        <FormattedMessage id={"register"}/>
      </Typography>
      <Typography variant={"subtitle2"} color={"text.secondary"}>
        <FormattedMessage id={"register-desc"}/>
      </Typography>
      <RegisterForm callback={onSignupSuccess}/>
    </Box>)}
    <Fade in={step === 1} unmountOnExit>
      <Box sx={{
        height: '100%',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}>
        <Typography variant={"h6"}>
          <FormattedMessage id={"register-success"}/>
        </Typography>
        <Typography>
          <FormattedMessage id={"register-success-desc"}/>
        </Typography>
      </Box>
    </Fade>
  </AppPublic>
}

const RegisterForm = ({ callback }: { callback: () => void }) => {

  const [ signup, signupRes ] = authApi.useRegisterMutation();
  const handleSignup = (s: SimpleFormData) => signup(s as RegisterBody);
  useEffect(() => {
    if (signupRes.isSuccess) callback();
  }, [ signupRes.isSuccess ]);

  const [ phoneValue, setPhoneValue ] = useState("");
  const handlePhone = (value: string) => setPhoneValue(value);

  return <SimpleForm onSubmit={handleSignup}>
    <FormControl fullWidth margin={"normal"} sx={{ mt: 3 }}>
      <TextField
        name={"name"}
        type={"text"}
        label={<FormattedMessage id={"business-name"}/>}
        required
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <AccountBox/>
          </InputAdornment>
        }}
      >

      </TextField>
    </FormControl>

    <FormControl margin={"dense"} fullWidth>
      <MuiTelInput
        name={"phone"}
        defaultCountry={"FR"}
        value={phoneValue}
        onChange={handlePhone}
      />
    </FormControl>

    <FormControl margin={"normal"} fullWidth>
      <TextField
        name={"email"}
        type={"email"}
        label={"Email"}
        required
        InputProps={{
          startAdornment: <InputAdornment position={"start"}>
            <Email/>
          </InputAdornment>
        }}
      />
    </FormControl>

    <Grid2 container spacing={2} mt={1}>
      <Grid2 xs={12} md>
        <FormControl fullWidth>
          <TextField
            name={"password"}
            type={"password"}
            label={<FormattedMessage id={"password"}/>}
            required
            InputProps={{
              startAdornment: <InputAdornment position={"start"}>
                <Password/>
              </InputAdornment>
            }}
          />
        </FormControl>
      </Grid2>
      <Grid2 xs={12} md>
        <FormControl fullWidth>
          <TextField
            name={"password_confirm"}
            type={"password"}
            label={<FormattedMessage id={"password-confirm"}/>}
            required
            InputProps={{
              startAdornment: <InputAdornment position={"start"}>
                <Password/>
              </InputAdornment>
            }}
          />
        </FormControl>
      </Grid2>
    </Grid2>

    <LoadingButton
      fullWidth
      data={signupRes}
      variant={"contained"}
      size={"large"}
      type={"submit"}
      sx={{
        mt: 2
      }}
    >
      <FormattedMessage id={"continue"}/>
    </LoadingButton>

  </SimpleForm>
}

export default Register;
