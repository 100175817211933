import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import profileApi from "../../../../app/services/profile";
import { useNotifications } from "../../../../assets/utils/utils";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { LoadingButton } from "../../Misc/Misc";
import React from "react";

export const CommonProfile = () => {
  const me = useAppSelector(getUser);
  const [ setProfile, setProfileRes ] = profileApi.useSetProfileMutation();
  useNotifications(setProfileRes, "success-edit-profile");
  return <>
    {me && (<SimpleForm onSubmit={(s) => setProfile(s as any)}>

      <Paper sx={{ p: 2 }}>
        <Box>

          <Grid2 spacing={2} container alignItems={"center"}>
            <Grid2 xs={10}>
              <FormControl fullWidth>
                <TextField
                  label={<FormattedMessage id={"your-email"}/>}
                  defaultValue={me?.email}
                  name={"authentication[email]"}
                />
              </FormControl>
            </Grid2>
            <Grid2 xs display={"flex"} justifyContent={"center"} textAlign={"center"}>
              {/*<FormControlLabel*/}
              {/*  control={<Switch name={"enable_trial"} defaultChecked={me?.enable_trial}/>}*/}
              {/*  label={<FormattedMessage id={"enable-trial"}/>}*/}
              {/*  labelPlacement={"bottom"}*/}
              {/*/>*/}
            </Grid2>
          </Grid2>
        </Box>

        <Box mt={1} display={"flex"} justifyContent={"end"}>
          <LoadingButton
            type={'submit'}
            variant={"contained"}
            data={setProfileRes}
          >
            <FormattedMessage id={"edit"}/>
          </LoadingButton>
        </Box>
      </Paper>
    </SimpleForm>)}
  </>
}
