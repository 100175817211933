import securedApi from "./securedApi";
import ApiUrls from "../apiUrls";
import { MFeed } from "./feedApi";

const customerApi = securedApi.injectEndpoints({
  endpoints: build => ({

    // Unused ATM
    getCustomers: build.query<Customer[], any>({
      query: (params) => ({
        url: ApiUrls.customers.list,
        params
      }),
      providesTags: [ "Customers" ]
    }),

    getCustomer: build.query<Customer, string | number>({
      query: (params) => ({
        url: ApiUrls.customers.info(params),
      }),
      providesTags: [ "Customer" ]
    }),

    editCustomer: build.mutation<Customer, EditCustomerApi>({
      query: ({ id, ...body }) => ({
        url: ApiUrls.customers.info(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Customer" ]
    }),

    autologin: build.mutation<AutologinResponse, string | number>({
      query: (id) => ({
        url: ApiUrls.customers.autologin(id),
        method: 'POST',
      })
    }),

    createCustomer: build.mutation<Customer, CreateCustomerBody>({
      query: (body) => ({
        url: ApiUrls.customers.list,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Customers" ]
    }),

    getCustomerPlans: build.query<Subscription, string | number>({
      query: (merchant) => ({
        url: ApiUrls.feeds.manageSubscription(merchant as string),
      }),
    }),

    initMerchantSubscription: build.mutation<FeedCheckData, SubscriptionPayload>({
      query: (arg) => ({
        url: ApiUrls.feeds.initSubscription(arg.id),
        method: 'POST',
        body: arg.data
      }),
      invalidatesTags: [ "Customers", "Feeds" ]
    }),

    // Update les abonnements d'un client
    updateMerchantSubscriptions: build.mutation<FeedCheckData, SubscriptionPayload>({
      query: (arg) => ({
        url: ApiUrls.feeds.manageSubscription(arg.id),
        method: 'POST',
        body: arg.data
      }),
      invalidatesTags: [ "Customers", "Feeds" ]
    }),

    cancelMerchantSubscription: build.mutation<any, string | number>({
      query: (feed) => ({
        url: ApiUrls.feeds.cancelSubscription(feed),
        method: 'POST',
      }),
      invalidatesTags: [ "Customers", "Feeds" ]
    })
  })
})

export default customerApi;

/** Types */
export type Customer = {
  id: number,
  commercial: null | CustomerCommercial,
  name: string,
  merchant_users: MUser[],
  feeds: MFeed[],
  address: Address,
  custom_id: string,
  created: number,
}

export type CustomerCommercial = Pick<Commercial, 'id' | 'first_name' | 'last_name'>;

export type MUser = {
  first_name: string | null,
  last_name: string | null,
  id: number,
  phone_number: string | null,
  authentication: {
    email: string,
    last_connection: number,
  },
}

export type CreateCustomerBody =
  Pick<MUser, 'first_name' | 'last_name' | 'authentication'>
  & { merchant: { name: string } }

export type Address = {
  address: string,
  address2: string,
  city: string,
  country: string,
  zip: string,
  invoice_email: string,
  phone_number: string,
  siret: string,
  vat_number: string,
  vat_exempted: boolean,
};

export type Commercial = {
  id: number,
  authentication: {
    email: string,
    affiliation_code: string,
  }
  first_name: string,
  last_name: string,
  phone: string,
  code: string,
}

export type EditCustomerApi = Pick<Customer, 'id' | 'address' | 'name'>;

export type AutologinResponse = {
  ttl: number,
  token: string,
};

export type Subscription = {
  is_linked: boolean,
  is_new: true,
  prices: Price[],
}

export type Price = {
  code: string,
  id: number,
  max_products: number,
  price: number,
  selected: boolean,
  type: "limits" | "pack" | "options",
}

export type FeedCheckData = {
  attributes: number,
  count: number,
  descriptions: number,
  missing_cat: number,
  missing_gtin: number,
  missing_ids: number,
  missing_images: number,
  samples: any,
  titles: number,
  url: string,
  website: string
}

export type SubscriptionPayload = {
  id: string | number,
  data: {
    feed: number | string,
    prices: number[],
  }
}
