import { Fade, Grow, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import gradient2 from "../../../../assets/img/gradient_2.png";
import Button from "@mui/material/Button";
import BusinessInfo from "./BusinessInfo";
import Typography from "@mui/material/Typography";
import InvoiceInfo from "./InvoiceInfo";
import profileApi, { OnboardingData } from "../../../../app/services/profile";
import { LoadingButton, pushGAEvent } from "../../Misc/Misc";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";

export type StepProps = {
  onSubmit: (data: Partial<OnboardingData>) => void,
  nav: ReactNode,
  data: Partial<OnboardingData>,
  size?: "small",
}

const Onboarding = () => {
  const me = useAppSelector(getUser);
  const need_onboarding = me?.need_onboarding;
  const waitingValidation = me?.validated;
  return <Box
    sx={{
      height: '100vh',
      overflowX: "hidden",
      backgroundImage: `url(${gradient2})`,
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Grow in>
      {
        need_onboarding
          ? <Box>
            <Typography variant={"h1"} textAlign={"center"} mb={2}>
              <FormattedMessage id={"welcome"}/>
            </Typography>
            <Fade in timeout={1000} style={{ transitionDelay: '400ms' }}>
              <Box>
                <OnboardingSteps/>
              </Box>
            </Fade>
          </Box>
          : <Box>
            <Typography variant={"h2"}><FormattedMessage id={"waiting-validation"} /></Typography>
            <Typography><FormattedMessage id={"waiting-validation-desc"} /></Typography>
          </Box>
      }
    </Grow>
  </Box>
}

const NB_STEPS = 2;

const OnboardingSteps = () => {
  const [ onboardingData, setOnboardingData ] = useState<Partial<OnboardingData>>({});
  const [ activeStep, setActiveStep ] = useState(0);

  const [ onboard, onboardRes ] = profileApi.useSetOnboardingMutation();

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const getOnboardingInfo = profileApi.useGetOnboardingQuery();
  useEffect(() => {
    if (getOnboardingInfo.isSuccess) {
      setOnboardingData(getOnboardingInfo.data);
    }
  }, [ getOnboardingInfo.isSuccess ]);

  const stepperNavigation = useMemo(() => <Box>
    <div>
      {activeStep < NB_STEPS - 1
        ? <Button
          variant="contained"
          sx={{ mt: 1, mr: 1 }}
          type={"submit"}
        >
          <FormattedMessage id={"continue"}/>
        </Button>
        : <LoadingButton
          data={onboardRes}
          variant={"contained"}
          sx={{ mt: 1, mr: 1 }}
          type={"submit"}
        >
          <FormattedMessage id={"finish"}/>
        </LoadingButton>
      }

      <Button
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mt: 1, mr: 1 }}
      >
        <FormattedMessage id={"back"}/>
      </Button>
    </div>

  </Box>, [ activeStep ]);

  const handleSubmit = (data: Partial<OnboardingData>) => {
    setOnboardingData(prev => ({ ...prev, ...data }));
    (activeStep < NB_STEPS - 1)
      ? handleNext()
      : apiSubmit({ ...onboardingData, ...data });
  };

  const apiSubmit = (data: Partial<OnboardingData>) => {
    onboard(data);
  }

  useEffect(() => {
    if (onboardRes.isSuccess) {
      window.location.reload();
    }
  }, [ onboardRes.isSuccess ]);

  const steps = [
    {
      label: <FormattedMessage id={"business-info"}/>,
      component: <BusinessInfo onSubmit={handleSubmit} nav={stepperNavigation} data={onboardingData}/>
    },
    {
      label: <FormattedMessage id={"invoice-info"}/>,
      component: <InvoiceInfo onSubmit={handleSubmit} nav={stepperNavigation} data={onboardingData}/>
    },
  ];
  return <Stepper
    activeStep={activeStep}
    orientation={"vertical"}
    sx={{
      width: '40vw'
    }}
  >
    {steps.map((step, index) =>
      (<Step key={`step-${index}`}>
        <StepLabel>
          {step.label}
        </StepLabel>
        <StepContent>
          {step.component}
        </StepContent>
      </Step>))}
  </Stepper>
}


export default Onboarding
