import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import { AppNotification, getAppNotifications, removeNotification } from "../../../app/slices/notifications";
import { Alert, Slide, Snackbar } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";

const AppNotifications = () => {
  const n = useAppSelector(getAppNotifications);
  return <div>
    {n.map(notif => <Notif {...notif} />)}
  </div>
}

const Notif = (n: AppNotification) => {
  const dispatch = useAppDispatch();
  const [ show, setShow ] = useState(false);

  const onClose = () => {
    setShow(false);
    setTimeout(() => dispatch(removeNotification()), 500);
  }

  useEffect(() => {
    n !== undefined && setShow(true);
  }, [ n ]);

  return <Slide in={show} direction={"up"}>
    <Snackbar
      autoHideDuration={4500}
      open={show}
      onClose={onClose}
    >
      <Alert severity={n.color}>
        <FormattedMessage id={n.message}/>
      </Alert>
    </Snackbar>
  </Slide>

}

export default AppNotifications;
