import AppSecured from "../../AppSecured";
import * as React from "react";
import { DashCards } from "./DashCards";
import DashSummary from "./DashSummary";
import { Dashboard as DashIcon, GroupAdd, People } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import RangeCalendar from "../../Misc/RangeCalendar";
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ReportingType } from "../../../../app/services/globalApi";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { getReportingParams, setReportingParams } from "../../../../app/slices/app";
import Grid2 from "@mui/material/Unstable_Grid2";
import { PartnerLink } from "../Program/Program";
import Paper from "@mui/material/Paper";

const Dashboard = () => {
  return <AppSecured>
    <Box display={"flex"} justifyContent={"end"} mb={2}>
      <PartnerLink/>
    </Box>
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <DashCards/>
        </Grid2>
        <Grid2 xs={12}>
          <DashSummary/>
        </Grid2>
      </Grid2>
    </Box>
  </AppSecured>
}

export default Dashboard;
