import Grid2 from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { ReactNode } from "react";

type PartnerCardProps = {
  icon: ReactNode,
  value: ReactNode,
  label: string | ReactNode,
}
export const PartnerCard = ({ icon, value, label }: PartnerCardProps) => {
  return <Grid2 xs={3}>
    <Paper sx={{
      px: 2,
      py: 1,
    }}>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={'100%'}
        justifyContent={"space-between"}
      >
        {icon}
        <Box
          textAlign={"end"}
          lineHeight={1}
        >
          <Typography variant={"caption"}>
            {label}
          </Typography>
          <Typography variant={"h5"} display={"flex"} justifyContent={"end"}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Paper>
  </Grid2>
}
