import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCustomHeaders } from "../apiUrls";

const publicApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => getCustomHeaders(headers, false),
    baseUrl: process.env.REACT_APP_API_DOMAIN
  }),
  endpoints: () => ({})
});

export default publicApi;
