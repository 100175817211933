import * as React from 'react';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { useAppDispatch, useAppSelector } from "../../../app/hooks/hooks";
import dayjs, { Dayjs } from "dayjs";
import { getReportingParams, setReportingParams } from "../../../app/slices/app";
import { formatDate, formatToLocaleDate } from "../../../assets/utils/utils";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { List, ListItemButton, ListItemText } from "@mui/material";
import { useLayoutEffect } from "react";
import { CalendarMonthTwoTone, ExpandMore } from "@mui/icons-material";

function Calendar() {
  const dispatch = useAppDispatch();
  const params = useAppSelector(getReportingParams);

  useLayoutEffect(() => {
    const select = document.getElementsByClassName("MuiDateRangeCalendar-root");
    if (select[0]) {
      const elem = select[0].getElementsByTagName("div");
      elem[0].innerHTML = "";
    }
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangeCalendar
        onChange={(e: DateRange<Dayjs>) => (e[0] && e[1]) && dispatch(setReportingParams({
          ...params, from: e[0]?.format("YYYY-MM-DD"), to: e[1]?.format("YYYY-MM-DD")
        }))}
      />
    </LocalizationProvider>
  );
}

const periods = [
  {
    label: "Mois en cours",
    period: [ dayjs().startOf("month").toString(), dayjs().toString() ]
  },
  {
    label: "Mois précédent",
    period: [ dayjs().startOf("month").subtract(1, "month").toString(), dayjs().startOf("month").toString() ]
  },
  {
    label: "3 derniers mois",
    period: [ dayjs().startOf("month").subtract(3, "month").toString(), dayjs().startOf("month").toString() ]
  },
  {
    label: "6 derniers mois",
    period: [ dayjs().startOf("month").subtract(6, "month").toString(), dayjs().startOf("month").toString() ]
  },
  {
    label: "Année en cours",
    period: [ dayjs().startOf("year").toString(), dayjs().toString() ]
  },
  {
    label: "Année précédente",
    period: [ dayjs().startOf("year").subtract(1, "year").toString(), dayjs().startOf("year").toString() ]
  },

]

export default function RangeCalendar() {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const params = useAppSelector(getReportingParams);
  const dispatch = useAppDispatch();

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        variant={"outlined"}
        sx={{
          textTransform:"none",
          fontWeight:400,
          letterSpacing:1,
          backgroundColor: "#fff",
          borderRadius:2,
          borderColor:"#cecece",
        }}
        startIcon={<CalendarMonthTwoTone />}
        endIcon={<ExpandMore />}
      >
        {formatToLocaleDate(params.from, true)}
        <Typography mx={1}>-</Typography>
        {formatToLocaleDate(params.to, true)}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box display={"flex"}>
          <Box minWidth={"15ch"}>
            <List>
              {periods.map(p => <ListItemButton
                key={`p-${p.label}`}
                onClick={() => {
                  dispatch(setReportingParams({ ...params, from: formatDate(p.period[0]), to: formatDate(p.period[1]) }))
                  handleClose();
                }}
              >
                <ListItemText primary={p.label}/>
              </ListItemButton>)}
            </List>
          </Box>
          <Calendar/>
        </Box>

      </Menu>
    </div>
  );
}
