import securedApi from "./securedApi";
import ApiUrls from "../apiUrls";
import { Commercial } from "./customerApi";

const globalApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getReporting: build.query<ReportingData, ReportingParams>({
      query: (params) => ({
        url: ApiUrls.global.reporting,
        params
      })
    }),
    getCountries: build.query<Array<Country>, void>({
      query: () => ApiUrls.global.countryList,
      providesTags: [ "GlobalCountries" ]
    }),
    getPeriods: build.query<ReportingData[], ReportingParams>({
      query: (params) => ({
        url: ApiUrls.global.periods,
        params
      })
    }),
    getCommercials: build.query<Commercial[], void>({
      query: () => ApiUrls.commercials.list,
      providesTags: [ "Commercials" ],
    }),
    createCommercial: build.mutation<Commercial, Commercial>({
      query: (body) => ({
        url: ApiUrls.commercials.list,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ "Commercials" ]
    })
  })
})

export default globalApi;

/** Types */
export type ReportingParams = {
  from: string,
  to: string,
  type: ReportingType,
  period?: ChartAggregations
}

export type ReportingType = 'new' | 'all';

export enum ChartAggregations {
  Weekly  = "w",
  Monthly = "m",
}

export type ReportingData = {
  "subscriptions": Array<any>,
  "direct_trial": number,
  "direct_autopilot": number,
  "direct_premium": number,
  "direct_trial_cancelled": number,
  "direct_autopilot_cancelled": number,
  "direct_premium_cancelled": number,
  "reseller_trial": number,
  "reseller_autopilot": number,
  "reseller_premium": number,
  "reseller_trial_cancelled": number,
  "reseller_autopilot_cancelled": number,
  "reseller_premium_cancelled": number,
  "direct_total_feeds": number,
  "reseller_total_feeds": number,
  "from": string,
  "to": string,
  registrations: number,
  registrations_linked:number,
  registrations_linked_subscriptions: number,
}

export type Country = {
  code: string,
  countryVats: Array<CountryVat | null | undefined>,
  defaultCurrencyCode: string | null,
  name: string,
  timezones: Array<string>,
  zone: string | null
}

export type CountryVat = {
  code: string,
  name: string,
  sampleValues: Array<string | null>
}
