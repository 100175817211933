import * as React from "react";
import customerApi from "../../../app/services/customerApi";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { VpnKey } from "@mui/icons-material";
import { CircularProgress, Popover } from "@mui/material";
import Button from "@mui/material/Button";
import Urls from "../../../app/appUrls";
import { useAppSelector } from "../../../app/hooks/hooks";
import { getUser } from "../../../app/slices/app";

type AutologinPopoverProps = {
  merchant: string | number,
  size?: "large" | "small"
}

const AutologinPopover = ({ merchant, size = "small" }: AutologinPopoverProps) => {

  const [ anchorEl, setAnchorEl ] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    autolog(merchant);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const me = useAppSelector(getUser);
  const [ autolog, autologRes ] = customerApi.useAutologinMutation();

  return <>
    {me?.autologin_enabled && (<>
      <Tooltip
        placement={"top"}
        title={"Autologin"}
        aria-describedby={id}
      >
        <IconButton size={size} onClick={handleClick}>
          <VpnKey fontSize={size}/>
        </IconButton>
      </Tooltip>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          boxShadow: "none",
          overflow: "hidden",
        }}
      >
        {autologRes.data
          ? <Button
            color={"info"}
            variant={"outlined"}
            fullWidth
            component={'a'}
            href={Urls.autologinCustomer(autologRes.data?.token)}
            target={'_blank'}
          >
            Login
          </Button>
          : <CircularProgress size={"2rem"}/>}
      </Popover>
    </>)}
  </>
}


export default AutologinPopover;
