import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AlertColor } from "@mui/material";

const initialState: NotificationsSlice = [];

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<AppNotification>) => {
      state.push(action.payload);
    },
    removeNotification: (state) => {
      state.pop();
    }
  }
})

export default notifications.reducer;

export const
  {
    addNotification,
    removeNotification,
  } = notifications.actions;

/** Types */
type NotificationsSlice = Array<AppNotification>

export type AppNotification = {
  color: AlertColor,
  message: string,
}

/** Getters */
export const getAppNotifications = (state: RootState) => state.notifications;
