import { MUIColor, RtkApiRes } from "../../app/types/misc";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/hooks/hooks";
import { addNotification } from "../../app/slices/notifications";
import { MFeed } from "../../app/services/feedApi";

export const formatToLocaleDate = (date: number | string, options?: boolean) => {
  return (new Date(
    typeof date === "number" ? date * 1000 : date
  ).toLocaleDateString("fr-FR", options ? {
    day: "2-digit", month: "short", year: "numeric"
  } : {}));
}

export const getFeedDates = (feed: MFeed) => {
  const sub = feed.current_subscription;

  return {
    start: sub?.date_start_subscription ? new Date(sub.date_start_subscription * 1000) : null,
    end: sub?.date_end_subscription ? new Date(sub.date_end_subscription! * 1000) : null,
    trial: sub?.date_end_trial ? new Date(sub.date_end_trial * 1000) : null,
    today: new Date(),
  };
};

export const getFeedSubStatus = (feed: MFeed) => {
  console.log(feed);
  if (feed !== null) {
    const sub = feed.current_subscription;
    if (!sub) return "free";

    if (sub.is_cancelled) return "canceled";

    const { end, trial } = getFeedDates(feed);

    console.log(end)
;
    if (trial && trial >= new Date()) return "trial";

    if (end && end >= new Date()) {
      return sub.plan.plan ? sub.plan.plan : "active";
    }
  }
  return "free";
};

export const SubStatusColor: Record<string, MUIColor> = {
  "free": "primary",
  "canceled": "error",
  "trial": "warning",
  "active": "success",
  "starter": "success",
  "premium": "success"
}

export function useNotifications(res: RtkApiRes<any>, message: string) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (res.isSuccess) {
      dispatch(addNotification({ color: "success", message }));
    }
  }, [ res.isSuccess, res.isError ]);
}

export const sumReducer = (prev: number, row: number) => prev + row;

export function useBoolean(initialState?: boolean) {
  const [ bool, setBool ] = useState(initialState ?? false);
  const setTrue = () => setBool(true);
  const setFalse = () => setBool(false);
  const setToggle = () => setBool(p => !p);

  return [
    bool,
    { setTrue, setFalse, setToggle }
  ] as const;
}

export function formatDate(date: string) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [ year, month, day ].join('-');
}
