import AppPublic from "../AppPublic";
import { FormattedMessage } from "react-intl";
import Typography from "@mui/material/Typography";
import SimpleForm from "../../../assets/utils/SimpleForm";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import authApi from "../../../app/services/auth";
import { useNotifications } from "../../../assets/utils/utils";
import Box from "@mui/material/Box";
import { LoadingButton } from "../../secured/Misc/Misc";
import { Link } from "react-router-dom";
import Urls from "../../../app/appUrls";
import { Email } from "@mui/icons-material";

const Forgot = () => {
  return <AppPublic
    actions={<Box display={"flex"} justifyContent={"end"} alignItems={"center"} mt={1}>
      <Typography color={"text.secondary"} variant={"body2"}>
        <FormattedMessage id={"remember-password"}/>
      </Typography>
      <Link to={Urls.login}>
        <Typography variant={"body2"} color={"text.secondary"} ml={0.5}>
          <FormattedMessage id={"login"}/>
        </Typography>
      </Link>
    </Box>}
  >
    <Typography variant={"h6"} sx={{ fontWeight: "bold" }} mb={1}>
      😬 <FormattedMessage id={"forgot-password"}/>
    </Typography>
    <Typography variant={"subtitle2"} color={"text.secondary"}>
      <FormattedMessage id={"forgot-password-desc"}/>
    </Typography>
    <ForgotForm/>
  </AppPublic>
}

const ForgotForm = () => {
  const [ forgot, forgotRes ] = authApi.usePasswordForgotMutation();
  useNotifications(forgotRes, "success-reset-password");
  return <>
    <SimpleForm onSubmit={(s) => forgot(s as { email: string })}>
      <FormControl margin={"normal"} fullWidth>
        <TextField
          label={"Email"}
          type={"email"}
          margin={"normal"}
          name={"email"}
          InputProps={{
            startAdornment: <InputAdornment position={"start"}>
              <Email/>
            </InputAdornment>
          }}
          required
        />
      </FormControl>

      <Box mt={3}>
        <LoadingButton
          fullWidth
          type={'submit'}
          size={'large'}
          data={forgotRes}
          variant={"contained"}
          sx={{
            py: 2
          }}
        >
          <FormattedMessage id={"reset-password"}/>
        </LoadingButton>
      </Box>
    </SimpleForm>
  </>
}

export default Forgot;
