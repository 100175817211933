import { StorageManager } from "../storage";
import { useEffect, useMemo } from "react";
import Urls from "../appUrls";

export function useIsLogged() {
  const token = StorageManager.get();
  const tokenMemo = useMemo(() => token, [ token ]);
  useEffect(() => {
    if (!tokenMemo) {
      window.location.href = Urls.login;
    }
  }, [ tokenMemo ]);
  return !!tokenMemo;
}
