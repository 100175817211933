import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import React from "react";

type PasswordsFormProps = {
  direction?: "row" | "column",
  current?: boolean,
}
export const PasswordsForm = ({ direction = "row", current = true }: PasswordsFormProps) => {
  return <>
    <Grid2 direction={direction} container spacing={2}>
      {current && (
        <Grid2 xs>
          <FormControl fullWidth>
            <TextField
              label={<FormattedMessage id={"your-current-password"}/>}
              type={"password"}
              name={"current"}
              required
            />
          </FormControl>
        </Grid2>)}
      <Grid2 xs>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"your-new-password"}/>}
            type={"password"}
            name={"password"}
            required
          />
        </FormControl>
      </Grid2>
      <Grid2 xs>
        <FormControl fullWidth>
          <TextField
            label={<FormattedMessage id={"your-new-password-confirmation"}/>}
            type={"password"}
            name={"password_confirm"}
            required
          />
        </FormControl>
      </Grid2>
    </Grid2>
  </>
}
