import securedApi from "./securedApi";
import ApiUrls from "../apiUrls";
import { ApiSuccess } from "../types/misc";
import { Address } from "./customerApi";

const profileApi = securedApi.injectEndpoints({
  endpoints: (build) => ({
    logout: build.mutation<void, void>({
      query: () => ({
        url: ApiUrls.auth.logout,
        method: 'POST',
      })
    }),
    getMe: build.query<Me, void>({
      query: () => ApiUrls.profile.me,
      providesTags: [ "Me" ],
    }),
    getProfile: build.query<Profile, void>({
      query: () => ApiUrls.profile.profile,
      providesTags: [ "Profile" ]
    }),
    setProfile: build.mutation<Profile, Partial<Profile>>({
      query: (body) => ({
        url: ApiUrls.profile.profile,
        method: 'POST',
        body,
      })
    }),
    setNewPassword: build.mutation<ApiSuccess, NewPasswordBody>({
      query: (body) => ({
        url: ApiUrls.profile.newPassword,
        method: 'POST',
        body,
      })
    }),
    getOnboarding: build.query<OnboardingData, void>({
      query: () => ApiUrls.profile.onboarding,
    }),
    setOnboarding: build.mutation<OnboardingData, Partial<OnboardingData>>({
      query: (body) => ({
        url: ApiUrls.profile.onboarding,
        method: 'POST',
        body,
      })
    })
  })
})

export default profileApi;

/** Types */
export type Me = {
  email: string,
  is_guest: boolean,
  token: string,
  code: string,
  type: ResellerTypes,
  is_linked?: boolean // For type = 1 only,
  name: string,

  autologin_enabled?: boolean,
  need_onboarding?: boolean
  validated?: boolean,

  // if merchant logs
  is_merchant?: boolean,
}

export enum ResellerTypes {
  "PAYER" = 1,
  "NON_PAYER",
  "NON_PAYER_COM",
  "SUB_PARTNER",
}

export type Profile = {
  name: string
  address: Address,
  linked: boolean,
  enable_trial: boolean,
  commission: {
    min_invoice_call: number,
    welcome_commission_amount: number,
    commission_rate: number,
  }
}

export type NewPasswordBody = {
  current: string,
  password: string,
  password_confirm: string,
}

export type PayerUpcomingInvoice = {
  amount: number,
  created: number,
  due_date: number,
  tax: number,
  lines: PayerInvoiceLine[]
}

export type PayerInvoiceLine = {
  amount: number,
  quantity: number,
  tax: number,
  title: string,
  unit_amount: object,
}

export type OnboardingData = {
  name: string,
  entity_type: EntityType,
  address: Address,
  bank_account: {
    account_number: string,
    account_owner: string,
    bank_code: string,
    bank_name: string,
  }
}

export enum EntityType {
  Company = 1,
  Independent,
}
