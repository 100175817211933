import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { getReportingParams, setReportingParams } from "../../../../app/slices/app";
import globalApi, { ReportingType } from "../../../../app/services/globalApi";
import Grid2 from "@mui/material/Unstable_Grid2";
import Vue from "../../Misc/Vue";
import { ResellerTypes } from "../../../../app/services/profile";
import * as React from "react";
import { sumReducer } from "../../../../assets/utils/utils";
import { FormattedMessage } from "react-intl";
import { useData } from "../../../../app/hooks/useData";
import { AppRegistration, AvTimer, GroupAdd, HowToReg, People } from "@mui/icons-material";
import { PartnerCard } from "../../Misc/PartnerCard";
import Box from "@mui/material/Box";
import RangeCalendar from "../../Misc/RangeCalendar";
import { Chip, Stack } from "@mui/material";

export const DashCards = () => {
  const reportingParams = useAppSelector(getReportingParams);
  const reportingQuery = globalApi.useGetReportingQuery(reportingParams);
  const reporting = reportingQuery.data;

  const [ { commissions, commissionInvoices } ] = useData();
  const sumCom = commissions.map(c => c.amount).reduce(sumReducer, 0) / 100;
  // const sumInvoice = commissionInvoices.map(i => i.commission_histories.map(h => h.amount).reduce(sumReducer, 0)).reduce(sumReducer, 0) / 100;


  return <Box sx={{
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }}>
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <ReportingTypeToggle/>
      <RangeCalendar/>
    </Box>

    <Grid2 sx={{flexGrow:1, justifyContent:"end"}} container spacing={2} p={0}>
      <PartnerCard
        icon={<AppRegistration fontSize={"large"} color={"action"}/>}
        value={(reporting?.registrations || 0) + (reporting?.registrations_linked || 0) + (reporting?.registrations_linked_subscriptions || 0)}
        label={<FormattedMessage id={"registers"}/>}
      />
      <PartnerCard
        icon={<AvTimer fontSize={"large"} color={"action"}/>}
        label={<FormattedMessage id={"trials"}/>}
        value={reporting?.reseller_trial}
      />
      <PartnerCard
        icon={<HowToReg fontSize={"large"} color={"action"}/>}
        label={<FormattedMessage id={"subscribers"}/>}
        value={(reporting?.reseller_autopilot ?? 0) + (reporting?.reseller_premium ?? 0)}
      />
      <Vue show={[ ResellerTypes["NON_PAYER_COM"] ]}>
        {/*<PartnerCard*/}
        {/*  icon={<AccountBalance fontSize={"large"}/>}*/}
        {/*  label={"Portefeuille"}*/}
        {/*  value={<FormattedNumber value={sumCom} style={"currency"} currency={"eur"}/>}*/}
        {/*/>*/}
        {/*<PartnerCard*/}
        {/*  icon={<CardMembership fontSize={"large"}/>}*/}
        {/*  label={<FormattedMessage id={"revenue"} />}*/}
        {/*  value={<FormattedNumber value={sumInvoice} style={"currency"} currency={"eur"}/>}*/}
        {/*/>*/}
      </Vue>

    </Grid2>
  </Box>
}

const ReportingTypeToggle = () => {
  const rParams = useAppSelector(getReportingParams);
  const dispatch = useAppDispatch();
  const setType = (type: ReportingType) => dispatch(setReportingParams({ ...rParams, type }));
  return (
    <Stack direction={"row"} spacing={1}>
      <Chip
        color={rParams.type === "all" ? "secondary" : undefined}
        variant={rParams.type === "all" ? "filled" : "outlined"}
        icon={<People />}
        label={<FormattedMessage id={"all-users"}/>}
        onClick={() => setType("all")}
        />

      <Chip
        color={rParams.type === "new" ? "secondary" : undefined}
        variant={rParams.type === "new" ? "filled" : "outlined"}
        icon={<GroupAdd/>}
        label={<FormattedMessage id={"new-users"}/>}
        onClick={() => setType("new")}
        />
    </Stack>
  )
}


export default DashCards;
