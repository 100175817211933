import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';

import './assets/scss/index.scss';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import ReactGA from "react-ga4";

const container = document.getElementById('root')!;
const root = createRoot(container);

/** GA4 */
const isDev = process.env.REACT_APP_APP_DOMAIN === "http://localhost:3000";
ReactGA.initialize("G-6E58S849ZH", {
  gaOptions: {
    debug_mode: isDev,
  },
  gtagOptions: {
    debug_mode: isDev,
  }
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
