import publicApi from "./publicApi";
import ApiUrls from "../apiUrls";
import { LoginData, LoginRes } from "../types/auth";

const authApi = publicApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginRes, LoginData>({
      query: (body) => ({
        url: ApiUrls.auth.login,
        method: 'POST',
        body,
      })
    }),
    passwordForgot: build.mutation<any, { email: string }>({
      query: (data) => ({
        url: ApiUrls.auth.forgot,
        method:'POST',
        body: data
      })
    }),
    passwordRefresh: build.mutation<void, RefreshBody>({
      query: ({ token, ...body }) => ({
        url: ApiUrls.auth.refresh(token),
        method:'POST',
        body,
      })
    }),
    register: build.mutation<void, RegisterBody>({
      query: (body) => ({
        url: ApiUrls.auth.register,
        method:'POST',
        body,
      })
    }),
    validate: build.mutation<{ success: boolean, email: string, company: string }, { token: string }>({
      query: (body) => ({
        url: ApiUrls.auth.validate(body.token),
        method: 'POST',
      })
    }),
  })
});

export default authApi;

/** Types */
export type RefreshBody = {
  token: string,
  password: string,
  password_confirm: string,
}

export type RegisterBody = {
  email: string,
  name: string,
  siren: string,
  password: string,
  password_confirm: string,
}

