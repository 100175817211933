const fr = {
  "login": "Connexion",

  "feed": "Boutique",
  "creation-date": "Date de création",
  "plan-type": "Type Plan",
  "accounts-connected": "Leviers connectés",
  "nb-products": "Nombre de produits",
  "access": "Accès",

  "canceled": "Annulé",
  "free": "Gratuit",
  "active": "Actif",
  "trial": "Essai",

  "your-customers": "Clients",
  "feed-product": "Flux produit",

  "date-first-sub": "Date 1er abo encaissé",
  "current-state": "État actuel",
  "date-next-cashback": "Date prochain encaissement",
  "gains": "Montant abonnement affilié HT",
  "new-subscriptions": "Nouveaux abonnements",
  "prime-new-subs": "Prime new abonnements HT",
  "commission-percentage": "% Commission Partenaire",
  "commission-gains": "Revenu commission Partenaire HT",
  "total-gains": "Total Revenu Partenaire HT",

  "invoicing": "Facturation",
  "invoice-month": "Mois Facturation",
  "date-gain": "Date encaissement",
  "partner-prime-new-sub": "Primes Partenaire New Abo HT",
  "gain-commission-partner": "Revenu Commission Partenaire HT",
  "total-gain-partner": "Total revenus Partenaire",
  "actions": "#",
  "starter": "Autopilot",
  "premium": "Premium",
  "summary": "Résumé",
  "all-users": "Tous vos utilisateurs",
  "new-users": "Nouveaux utilisateurs",
  "aggregation": "Aggrégation",

  "weekly": "Semaine",
  "monthly": "Mois",
  "users": "Utilisateurs",
  "first-name": "Prénom",
  "last-name": "Nom",
  "phone-number": "Téléphone",
  "last-seen": "Dernière connexion",
  "address": "Adresse",
  "company-name": "Dénomination sociale",
  "address2": "Adresse (complément)",
  "city": "Ville",
  "zip": "Code postal",
  "country": "Pays",
  "vat-number": "Numéro TVA",
  "vat-type": "Type TVA",
  "siret": "SIRET",
  "invoice-email": "Email de facturation",
  "edit": "Modifier",
  "shops": "Boutiques",
  "please-wait": "Veuillez patienter...",
  "success-edit-customer": "Succès édition client !",
  "profile": "Profil",
  "logout": "Déconnexion",
  "your-register-link": "Votre lien d'affiliation",
  "copied-in-clipboard": "Copié dans le presse-papier !",
  "program": "Programme",
  "commissions": "Revenus",
  "your-email": "Votre email de connexion",
  "enable-trial": "Activer la période d'essai pour vos clients",
  "success-edit-profile": "Succès édition de vos informations !",
  "passwords": "Mot de passe",
  "your-current-password": "Mot de passe actuel",
  "your-new-password": "Nouveau mot de passe",
  "your-new-password-confirmation": "Nouveau mot de passe (confirmation)",
  "success-edit-passwords": "Succès édition password",
  "commission-rate": "Taux de commission",
  "welcome-commission-amount": "Prime de nouvel abonnement",
  "min-invoice-call": "Minimum pour appel à facturation",
  "copy-in-clipboard": "Copier",
  "claim-gains": "Générer un appel à facturation",
  "invoice-info": "Informations de facturation",
  "success-edit-address": "Succès édition de votre adresse !",
  "edit-value": "Éditer {merchant}",
  "cumulated-ca": "CA Cumulé",
  "date-end-subscription": "Prochaine facturation",
  "create-customer": "Créer un client",
  "customers": "Clients",
  "create": "Créer",
  "success-customer-creation": "Succès création client !",
  "informations": "Informations",
  "customId": "ID Personnalisé",
  "commercials": "Mes partenaires",
  "phone": "Téléphone",
  "create-commercial": "Créer partenaire",
  "new-commercial": "Nouveau partenaire",
  "new-customer": "Nouveau client",
  "success-commercial-creation": "Succès création partenaire !",
  "subscription-amount": "Abonnements",
  "commercial": "Partenaire",
  "name": "Nom",
  "forgot-password": "Mot de passe oublié",
  "success-reset-password": "Nous vous avons envoyé un email pour réinitialiser votre mot de passe !",
  "success-refresh-password": "Votre mot de passe a bien été modifié ! Vous allez être redirigé vers la page de connexion...",
  "product": "Produit",
  "quantity": "Quantité",
  "amount": "Montant",
  "tax": "Taxe",
  "created": "Créé le {date}",
  "due-date": "Pour le {date}",
  "invoice-history": "Historique de facturation",
  "current-invoice": "Facturation en cours",
  "copy-subpartner-code": "Copier lien d'inscription",
  "date-creation": "Date",
  "code": "Code",
  "handle-subscription": "Gérer l'abonnement",
  "status": "Status",
  "success-init-sub": "Succès du lancement de l'abonnement",
  "success-edit-sub": "Succès édition de l'abonnement",
  "validate": "Valider",
  "cancel-subscription": "Annuler l'abonnement",
  "cancel-subscription-ask": "Êtes-vous certain de vouloir annuler l'abonnement de cette boutique ?",
  "confirm-cancel-sub": "Annuler l'abonnement en cours",
  "cancel": "Fermer",
  "success-update-sub": "L'abonnement a bien été mis à jour",
  "opt_product_level_1": "Limite",
  "opt_product_level_2": "Limite",
  "opt_product_level_3": "Limite",
  "opt_product_level_4": "Limite",
  "products": "produits",
  "success-cancel-sub": "Succès annulation de votre abonnement.",
  "media-spent": "Dépenses Média",
  "no-data": "Aucune donnée(s) !",
  "no-data-desc": "Vous n'avez pas encore de boutique affiliée. Rendez-vous dans \"Mon Programme\" pour commencer votre d'affiliation !",
  "no-data-desc-2": "Lorsque vous aurez des boutiques affiliées, vous pourrez les consulter ici.",
  "your-affiliates": "Boutiques",
  "forgotten-password": "Mot de passe oublié?",
  "welcome-back": "Heureux de vous revoir!",
  "password": "Mot de passe",
  "login-to-continue": "Connectez-vous pour accéder à l'application Partner.",
  "forgot-password-desc": "Ça arrive aux meilleurs d'entre nous.",
  "reset-password": "Réinitialiser mon mot de passe",
  "remember-password": "Vous vous souvenez de votre mot de passe? ",
  "register": "Devenir partenaire",
  "register-desc": "Vous souhaitez devenir Partenaire Feedcast ?",
  "password-confirm": "Mot de passe (confirmation)",
  "continue": "Continuer",
  "registers": "Inscriptions",
  "trials": "Période d'essai",
  "subscribers": "Abonnés",
  "revenue": "Revenu",
  "business-name": "Raison sociale",
  "register-success": "Votre inscription a bien été enregistrée !",
  "register-success-desc": "Il faut valider votre compte avec le lien reçu dans votre boîte email. 🥳",
  "account-validated": "Compte validé",
  "bank-code": "Code banque (BIC)",
  "bank-account": "Numéro de compte (IBAN)",
  "bank-owner": "Titulaire du compte",
  "bank-name": "Nom de la banque",
  "business-info": "Informations de l'entreprise",
  "welcome": "Bienvenue",
  "company-type": "Type d'entreprise",
  "siren": "SIREN",
  "company": "Entreprise",
  "independent": "Indépendant",
  "has-tva": "Assujetti à la TVA",
  "no-shops": "Aucune boutique !",
  "your-affiliation-link": "Votre lien d'affiliation",
  "copy-affiliation-link": "Copier mon lien d'affiliation",
  "Pending": "En attente",
  "Payed": "Payée",
  "by-checking-this": "En cochant cette case, j'atteste avoir lu et accepté les ",
  "cgv": "conditions générales d'utilisation de vente",
  "waiting-validation": "En attente de validation",
  "waiting-validation-desc": "Merci ! Nous allons maintenant vérifier votre compte, et nous reviendrons vers vous d'ici 48 heures !",
}

export default fr;
