import AppSecured from "../../AppSecured";
import Typography from "@mui/material/Typography";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import { ResellerTypes } from "../../../../app/services/profile";
import { PayerUpcoming } from "./PayerUpcoming";
import { PayerInvoicesHistory } from "./PayerInvoicesHistory";

const PayerInvoices = () => {
  return <AppSecured
    restrict={[ ResellerTypes["PAYER"] ]}
  >
    {/*<header>*/}
    {/*  <Box mb={1}>*/}
    {/*    <Typography variant={"h4"} display={"flex"} alignItems={"center"}>*/}
    {/*      <ReceiptLongOutlined fontSize={"large"} sx={{ mr: 1 }}/>*/}
    {/*      <FormattedMessage id={"invoicing"}/>*/}
    {/*    </Typography>*/}
    {/*  </Box>*/}
    {/*</header>*/}
    <PayerUpcoming/>
    <PayerInvoicesHistory/>
  </AppSecured>
}

export default PayerInvoices;
