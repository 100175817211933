import { ResellerTypes } from "../../../app/services/profile";
import { useAppSelector } from "../../../app/hooks/hooks";
import { getUser } from "../../../app/slices/app";
import React from "react";

type VueProps = {
  show: ResellerTypes[],
  children: any,
}

const Vue = (p: VueProps) => {
  const user = useAppSelector(getUser);
  return <React.Fragment>
    {user && user.type && p.show.includes(user.type) && <React.Fragment>
      {p.children}
    </React.Fragment>}
  </React.Fragment>
}

export default Vue;
