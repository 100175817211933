import React from 'react';
import { Navigate, Routes } from "react-router-dom";
import { Route } from "react-router";
import Login from "./components/public/Auth/Login";
import Urls from "./app/appUrls";
import { IntlProvider } from "react-intl";
import fr from "./assets/languages/fr";
import Dashboard from "./components/secured/App/Dashboard/Dashboard";
import Commissions from "./components/secured/App/Commissions/Commissions";
import CommissionsInvoices from "./components/secured/App/Commissions/CommissionsInvoices";
import CustomerEdit from "./components/secured/App/Customer/CustomerEdit";
import AppNotifications from "./components/secured/Misc/AppNotifications";
import Program from "./components/secured/App/Program/Program";
import Profile from "./components/secured/App/Profile/Profile";
import Customers from "./components/secured/App/Customer/Customers";
import SubPartners from "./components/secured/App/SubPartners/SubPartners";
import Autologin from "./components/public/Auth/Autologin";
import Forgot from "./components/public/Auth/Forgot";
import Refresh from "./components/public/Auth/Refresh";
import PayerInvoices from "./components/secured/App/PayerInvoices/PayerInvoices";
import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import Chatbot from "./Chatbot";
import Register from "./components/public/Auth/Register";
import Validate from "./components/public/Auth/Validate";
import AppRedirect from "./components/public/AppRedirect";

function App() {
  const theme = makeAppTheme();
  return (
    <IntlProvider locale={"fr"} defaultLocale={"fr"} messages={fr}>
      <ThemeProvider theme={theme}>
        <div id={'app'}>
          <AppRedirect/>
          <Routes>
            <Route path={Urls.login} element={<Login/>}/>
            <Route path={Urls.register} element={<Register/>} />
            <Route path={Urls.forgot} element={<Forgot/>}/>
            <Route path={Urls.refresh(":token")} element={<Refresh/>}/>
            <Route path={Urls.validate(":token")} element={<Validate/>} />
            <Route path={Urls.app.dash} element={<Dashboard/>}/>
            <Route path={Urls.app.commissions} element={<Commissions/>}/>
            <Route path={Urls.app.invoices} element={<CommissionsInvoices/>}/>
            <Route path={Urls.app.invoicesPayer} element={<PayerInvoices/>}/>
            <Route path={Urls.app.program} element={<Program/>}/>
            <Route path={Urls.app.profile} element={<Profile/>}/>
            <Route path={Urls.app.customers} element={<Customers/>}/>
            <Route path={Urls.app.customerEdit(":customerId")} element={<CustomerEdit/>}/>
            <Route path={Urls.app.commercials} element={<SubPartners/>}/>
            <Route path={Urls.autologin} element={<Autologin/>}/>
            <Route path={"/*"} element={<Navigate to={Urls.login}/>}/>
          </Routes>
          <AppNotifications/>
          <Chatbot/>
        </div>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;

const makeAppTheme = () => {

  const themeOptions: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#06053d',
        // main: "#524FF0",
      },
      secondary: {
        main: '#614DFA',
      },
      background: {
        default: '#fafafa',
      },
    },
    shape: {
      // borderRadius: 16,
    },
    typography: {
      fontSize: 12,
      fontFamily: "Rubik, Roboto"
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...({
              borderRadius: 8,
              // boxShadow: "0 4px #06053d",
              // border:"2px solid #06053d",
            })
          })
        }
      },
      // MuiButton: {
      //   styleOverrides: {
      //     root: ({ownerState}) => ({
      //       ...(ownerState.variant === "outlined" && {
      //         borderRadius: 8,
      //         background:"#fff",
      //         border:"2px solid #06053d",
      //         "&:hover": {
      //           border:"2px solid #06053d",
      //         }
      //       })
      //     })
      //   }
      // }
    }
  };

  return createTheme(themeOptions);
}
