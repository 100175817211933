import AppSecured from "../../AppSecured";
import { ResellerTypes } from "../../../../app/services/profile";
import { Link, useParams } from "react-router-dom";
import customerApi from "../../../../app/services/customerApi";
import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ArrowBackRounded, StorefrontOutlined } from "@mui/icons-material";
import Urls from "../../../../app/appUrls";
import Typography from "@mui/material/Typography";
import CustomerUsers from "./CustomerUsers";
import CustomerAddress from "./CustomerAddress";
import { formatToLocaleDate, sumReducer } from "../../../../assets/utils/utils";
import DashReporting from "../Dashboard/DashReporting";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Button from "@mui/material/Button";
import Grid2 from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import AutologinPopover from "../AutologinPopover";

const CustomerEdit = () => {

  const { customerId } = useParams();
  const customerIdMemo = useMemo(() => customerId, [ customerId ]);
  const [ autolog, autologRes ] = customerApi.useAutologinMutation();
  const [ getCustomer, customerQuery ] = customerApi.useLazyGetCustomerQuery();
  const customer = customerQuery.data;

  useEffect(() => {
    if (customerIdMemo) {
      getCustomer(customerIdMemo, true);
      // autolog(customerIdMemo); TODO
    }
  }, [ customerIdMemo ]);

  return <AppSecured
    restrict={[ ResellerTypes["NON_PAYER"], ResellerTypes["PAYER"], ResellerTypes.SUB_PARTNER ]}
    query={customerQuery}
  >
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"end"}
    >
      <Box display={"flex"}>
        <IconButton
          component={Link}
          to={Urls.app.dash}
        >
          <ArrowBackRounded/>
        </IconButton>
        <Typography variant={'h3'}>{customer?.name}</Typography>
        {customer && <Box mx={2}>
            <AutologinPopover size={'large'} merchant={customer.id}/>
        </Box>}
      </Box>

      <Box display={"flex"} alignItems={"center"}>
        <Typography variant={"caption"}>
          Inscrit le {customer?.created && formatToLocaleDate(customer?.created)}</Typography>
        {autologRes.data && (
          <Button
            sx={{ ml: 2 }}
            variant={"outlined"}
            component={'a'}
            href={Urls.autologinCustomer(autologRes.data?.token)}
            target={'_blank'}
          >
            Autologin
          </Button>)}
      </Box>
    </Box>

    <CustomerUsers
      users={customer?.merchant_users ?? []}
      merchant={customer?.id}
    />

    <CustomerFeeds
      merchant_id={customer?.id}
    />

    <CustomerAddress
      address={{
        ...customer?.address,
        name: customer?.name,
        id: customer?.id,
        custom_id: customer?.custom_id,
        commercial: customer?.commercial,
      } ?? {}}
    />

  </AppSecured>
}

type CustomerFeedsProps = {
  merchant_id?: string | number,
}

const CustomerFeeds = (p: CustomerFeedsProps) => {
  return <Box mt={2}>
    {/*<Typography variant={"h5"} sx={{ display: "flex", alignItems: "center" }}>*/}
    {/*  <StorefrontOutlined sx={{ mr: 1 }}/>*/}
    {/*  <FormattedMessage id={"shops"}/>*/}
    {/*</Typography>*/}

    <DashReporting
      merchant_id={p.merchant_id}
    />
  </Box>
}

export default CustomerEdit;
