import AppSecured from "../../AppSecured";
import { ResellerTypes } from "../../../../app/services/profile";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Chip, FormControl, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { ContentCopy, Discount, Loyalty, Percent, RequestPage } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { addNotification } from "../../../../app/slices/notifications";
import Box from "@mui/material/Box";
import { PartnerCard } from "../../Misc/PartnerCard";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Vue from "../../Misc/Vue";

/**
 * Program.tsx
 * CGV, user commission info
 */

const Program = () => {
  const user = useAppSelector(getUser);
  return <AppSecured
    restrict={[ ResellerTypes["NON_PAYER_COM"], ResellerTypes.SUB_PARTNER ]}
  >
    <Stack direction={"row"} justifyContent={"space-between"}>
      {/*<Typography variant={"h4"} display={"flex"} alignItems={"center"}>*/}
      {/*  <Loyalty fontSize={"large"} sx={{ mr: 1 }}/>*/}
      {/*  <FormattedMessage id={"program"}/>*/}
      {/*</Typography>*/}
      <PartnerLink/>
    </Stack>

    <Grid2 container mt={1} spacing={2}>
      <Grid2 xs={3}>
        <PartnerCard
          icon={<Percent sx={{ fontSize: "4rem" }}/>}
          label={<FormattedMessage id={"commission-rate"}/>}
          value={<FormattedNumber value={(user?.commission?.commission_rate ?? 0) / 100 ?? 0} style={"percent"}/>}
        />

        <PartnerCard
          icon={<Discount sx={{ fontSize: "4rem" }}/>}
          label={<FormattedMessage id={"welcome-commission-amount"}/>}
          value={<FormattedNumber value={user?.commission?.welcome_commission_amount ?? 0} style={"currency"}
                                  currency={"eur"}/>}
        />

        <PartnerCard
          icon={<RequestPage sx={{ fontSize: "4rem" }}/>}
          label={<FormattedMessage id={"min-invoice-call"}/>}
          value={<FormattedNumber value={user?.commission?.min_invoice_call ?? 0} style={"currency"} currency={"eur"}/>}
        />
      </Grid2>
      <Grid2 xs>
        {/*<Paper sx={{ p: 2 }}>*/}
          <Box pr={4}>
            <Typography variant={"body2"} textAlign={"justify"}>
              Bienvenue au programme Partenaires de Feedcast ! Nous sommes ravis de voir que vous avez décidé de
              rejoindre notre programme. <br/>
              En rejoignant notre programme, vous deviendrez un ambassadeur de Feedcast, aidant à faire connaître notre
              solution à une audience plus large et, en retour, recevant une compensation pour vos efforts.<br/>
              <br/>
              Avant de commencer, il est important que vous preniez le temps de lire et de comprendre <a
              href={"/cgv-feedcast-partner.pdf"}>nos Conditions
              Générales de Vente (CGV)</a>. En vous inscrivant à notre programme d'affiliation, vous indiquez que vous
              acceptez et vous engagez à respecter ces conditions. Les CGV détaillent vos obligations en tant
              qu'affilié, ainsi que les nôtres en tant que fournisseur de la solution Feedcast.<br/>
              <br/>
              Veuillez noter que les CGV peuvent être modifiées de temps à autre pour refléter les changements dans nos
              politiques ou les lois applicables. Nous vous encourageons donc à les consulter régulièrement. Si vous
              continuez à participer à notre programme d'affiliation après une modification des CGV, cela signifie que
              vous acceptez ces modifications.<br/>
              <br/>
              Si vous avez des questions ou des préoccupations concernant les CGV, n'hésitez pas à nous contacter.
              Nous sommes impatients de collaborer avec vous. Ensemble, nous pouvons aider davantage de personnes à
              découvrir et à bénéficier de la solution Feedcast.<br/>
              <br/>
              En vous inscrivant à notre programme d'affiliation, vous acceptez que vous avez lu, compris et accepté de
              vous conformer à nos <a href={"/cgv-feedcast-partner.pdf"} target={'_blank'}>Conditions Générales de Vente</a>. Nous vous remercions de votre confiance et sommes
              ravis de vous accueillir dans notre programme d'affiliation.<br/>
            </Typography>
          </Box>
        {/*</Paper>*/}
      </Grid2>
    </Grid2>
  </AppSecured>
}

export const PartnerLink = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUser);
  const affiliationUrl = `https://app.feedcast.io/register/${user?.code ?? ""}.html`;
  return (
    <Vue show={[ ResellerTypes["NON_PAYER_COM"] ]}>
      <Box>
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <Tooltip title={<FormattedMessage id={"your-affiliation-link"}/>}>
            <Chip
              color={"primary"}
              label={affiliationUrl}
              deleteIcon={<ContentCopy fontSize={"small"} />}
              onDelete={() => {
                navigator.clipboard.writeText(affiliationUrl);
                dispatch(addNotification({
                  color: 'info',
                  message: "copied-in-clipboard"
                }))
              }}
            />
          </Tooltip>
          {/*<Tooltip title={<FormattedMessage id={"copy-affiliation-link"}/>}>*/}
          {/*  /!*<IconButton*!/*/}
          {/*  /!*  color={"primary"}*!/*/}
          {/*  /!*  onClick={(e) => {*!/*/}
          {/*  /!*    navigator.clipboard.writeText(affiliationUrl);*!/*/}
          {/*  /!*    dispatch(addNotification({*!/*/}
          {/*  /!*      color: 'info',*!/*/}
          {/*  /!*      message: "copied-in-clipboard"*!/*/}
          {/*  /!*    }))*!/*/}
          {/*  /!*  }}*!/*/}
          {/*  /!*>*!/*/}
          {/*  /!*  *!/*/}
          {/*  /!*</IconButton>*!/*/}
          {/*</Tooltip>*/}
        </Stack>
      </Box>
    </Vue>

  )
}

export default Program;
