import React from "react";
import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AppSecured from "../../AppSecured";
import { ResellerTypes } from "../../../../app/services/profile";
import { CommissionType, InvoiceCallStatus } from "../../../../app/services/invoiceApi";
import TableRow from "@mui/material/TableRow";
import { formatToLocaleDate, sumReducer } from "../../../../assets/utils/utils";
import { ReceiptLongOutlined } from "@mui/icons-material";
import { useData } from "../../../../app/hooks/useData";
import { Paper } from "@mui/material";

/**
 * CommissionsInvoices.tsx
 * Displays the partner's invoice calls : pending or closed
 */

const CommissionsInvoices = () => {
  return <AppSecured
    restrict={[ ResellerTypes["NON_PAYER_COM"] ]}
  >
    <Box mb={1}>
      <Typography variant={"h4"} display={"flex"} alignItems={"center"}>
        <ReceiptLongOutlined fontSize={"large"} sx={{ mr: 1 }}/>
        <FormattedMessage id={"invoicing"}/>
      </Typography>
    </Box>
    <Paper sx={{overflow:"hidden"}}>
      <Box>
        <InvoicesTable/>
      </Box>
    </Paper>
  </AppSecured>
}

const InvoicesTable = () => {
  const [ { commissionInvoices } ] = useData();
  return <React.Fragment>
    <FTable
      className={"table-text-end"}
      head={<>
        <TableCell>Code</TableCell>
        <TableCell><FormattedMessage id={"invoice-month"}/></TableCell>
        <TableCell><FormattedMessage id={"status"}/></TableCell>
        <TableCell><FormattedMessage id={"partner-prime-new-sub"}/></TableCell>
        <TableCell><FormattedMessage id={"gain-commission-partner"}/></TableCell>
        <TableCell><FormattedMessage id={"total-gain-partner"}/></TableCell>
        {/*<TableCell/>*/}
      </>}
    >
      {commissionInvoices.map(i => {
        const comNew = i.commissions.filter(c => c.type === CommissionType["Welcome commission"]).map(c => c.amount).reduce(sumReducer, 0);
        const comPartner = i.commissions.filter(c => c.type === CommissionType["Invoice commission"]).map(c => c.amount).reduce(sumReducer, 0);
        return <TableRow key={`i-${i.created}`}>
          <TableCell>{i.code}</TableCell>
          <TableCell>{formatToLocaleDate(i.created, true)}</TableCell>
          <TableCell><FormattedMessage id={InvoiceCallStatus[i.status]} /></TableCell>
          <TableCell><FormattedNumber value={comNew} style={"currency"} currency={"eur"}/></TableCell>
          <TableCell><FormattedNumber value={comPartner} style={"currency"} currency={"eur"}/></TableCell>
          <TableCell><FormattedNumber value={comPartner + comNew} style={"currency"} currency={"eur"}/></TableCell>
          {/*<TableCell>*/}
          {/*  <IconButton>*/}
          {/*    <CloudDownloadOutlined/>*/}
          {/*  </IconButton>*/}
          {/*</TableCell>*/}
        </TableRow>
      })}
    </FTable>
  </React.Fragment>
}

export default CommissionsInvoices;
