import React, { useEffect } from 'react';
import { StorageManager } from "../../../app/storage";
import Urls from "../../../app/appUrls";
const Autologin = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    StorageManager.set(searchParams.get("token") as string);
    setTimeout(() => {
      window.location.href = Urls.app.dash;
    }, 500);
  }, [])
  return <></>
}

export default Autologin;
