const key = "fc_access_token";
export const StorageManager = {
  get: (): string => window.localStorage.getItem(key) as string,
  set: (token: string): void => {
    window.localStorage.setItem(key, token);
  },
  clear: (): void => {
    window.localStorage.removeItem(key);
  },
}
