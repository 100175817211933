import { useData } from "../../../../app/hooks/useData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage, FormattedNumber } from "react-intl";
import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/material";
import { formatToLocaleDate } from "../../../../assets/utils/utils";
import Paper from "@mui/material/Paper";

export const PayerUpcoming = () => {
  const [ { payerUpcoming } ] = useData();
  return (
    <Box>
      <Typography variant={'subtitle1'}><FormattedMessage id={"current-invoice"}/></Typography>
      <Paper sx={{overflow:"hidden"}}>
        <FTable
          className={"table-text-end"}
          head={<>
            <TableCell><FormattedMessage id={"product"}/></TableCell>
            <TableCell><FormattedMessage id={"quantity"}/></TableCell>
            <TableCell><FormattedMessage id={"amount"}/></TableCell>
            <TableCell><FormattedMessage id={"tax"}/></TableCell>
          </>}
        >
          {payerUpcoming?.lines?.map(l => <TableRow key={`l-${l.title}`}>
            <TableCell>{l.title}</TableCell>
            <TableCell>{l.quantity}</TableCell>
            <TableCell><FormattedNumber value={l.amount / 100} style={"currency"} currency={"eur"}/></TableCell>
            <TableCell><FormattedNumber value={l.tax / 100} style={"currency"} currency={"eur"}/></TableCell>
          </TableRow>)}
          <TableRow sx={{
            backgroundColor: "#06053d",
            "& > td": { color: "#FAFAFA" }
          }}>
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell><FormattedNumber value={(payerUpcoming?.amount || 0) / 100 ?? 0} style={"currency"}
                                        currency={"eur"}/></TableCell>
            <TableCell><FormattedNumber value={(payerUpcoming?.tax || 0) / 100 ?? 0} style={"currency"} currency={"eur"}/></TableCell>
          </TableRow>
        </FTable>
      </Paper>
      {payerUpcoming && (<Stack display={"flex"} justifyContent={"end"} direction={"row"} spacing={1} mt={1}>
        <Typography variant={"caption"}>
          <FormattedMessage id={"created"} values={{ date: formatToLocaleDate(payerUpcoming.created, true) }}/>
        </Typography>
        <div>|</div>
        <Typography variant={"caption"}>
          <FormattedMessage id={"due-date"} values={{ date: formatToLocaleDate(payerUpcoming?.due_date, true) }}/>
        </Typography>
      </Stack>)}
    </Box>
  )
}
