import {isRejectedWithValue, Middleware, MiddlewareAPI} from "@reduxjs/toolkit";
import { addNotification } from "../slices/notifications";
import Urls from "../appUrls";

export const httpErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status: string = (action.payload.originalStatus || action.payload.status).toString();

    switch (status) {
      case "401":
        // api.dispatch(appRedirect(Urls.login));
        window.location.href = Urls.login;
        break;

      case "403":
        api.dispatch(addNotification({
          color: "error",
          message: "Accès à une ressource non autorisé",
        }));
        break;

      case "404":
        api.dispatch(addNotification({
          color: "error",
          message: "Accès à une ressource inconnue",
        }));
        break;

      case "500":
        api.dispatch(addNotification({
          color: "error",
          message: action.payload?.data?.error || "Erreur serveur",
        }));
        break;

      case "PARSING_ERROR":
        api.dispatch(addNotification({
          color: "error",
          message: "Erreur serveur inconnue",
        }));
        break;

      case "login_error":
        api.dispatch(addNotification({
          color: "error",
          message: "Identifiant invalide",
        }));
        break;

      default:
        api.dispatch(addNotification({
          color: "error",
          message: `Erreur HTTP non prise en charge (${status})`,
        }));
        break;
    }
  }

  return next(action);
}
