import { useAppSelector } from "./hooks";
import { getReportingParams, getUser } from "../slices/app";
import feedApi from "../services/feedApi";
import invoiceApi from "../services/invoiceApi";
import profileApi, { ResellerTypes } from "../services/profile";
import { useEffect, useState } from "react";
import globalApi from "../services/globalApi";
import customerApi from "../services/customerApi";

export function useData() {

  const me = useAppSelector(getUser);
  const params = useAppSelector(getReportingParams);

  const feedsQ = feedApi.useGetFeedsQuery({metrics_from: params.from, metrics_to: params.to});
  const [ getCustomers, customersQuery ] = customerApi.useLazyGetCustomersQuery();

  const [ getCommissions, commissionQuery ] = invoiceApi.useLazyGetCommissionsQuery();
  const [ getCommissionInvoices, commissionInvoicesQuery ] = invoiceApi.useLazyGetBilledCommissionsQuery();
  const [ getSubPartners, subPartnersRes ] = globalApi.useLazyGetCommercialsQuery();

  const [ getPayerUpcoming, payerUpcomingRes ] = invoiceApi.useLazyGetPayerUpcomingQuery();
  const [ getPayerInvoices, payerInvoicesRes ] = invoiceApi.useLazyGetPayerInvoicesQuery();

  const [ loading, setLoading ] = useState(false);

  useEffect(() => {
    if (me && me.type && !me.need_onboarding) {
      getCustomers({}, true);
      switch (me.type) {
        case ResellerTypes["NON_PAYER_COM"]:
          getCommissions(undefined, true);
          getCommissionInvoices(undefined, true);
          getSubPartners(undefined, true);
          break;
        case ResellerTypes["PAYER"]:
          getPayerInvoices(undefined, true)
          getPayerUpcoming(undefined, true);
          break;
        case ResellerTypes["NON_PAYER"]:
          getSubPartners(undefined, true);
          break;
      }
    }
  }, [ me?.type ]);

  useEffect(() => {
    (commissionQuery.isLoading
      || commissionInvoicesQuery.isLoading)
      ? setLoading(true)
      : setTimeout(() => setLoading(false), 2000);
  }, [ commissionQuery, commissionInvoicesQuery ])

  return [
    {
      feeds: feedsQ.data ?? [],
      customers: customersQuery.data ?? [],
      commissions: commissionQuery.data ?? [],
      commissionInvoices: commissionInvoicesQuery.data ?? [],
      payerUpcoming: payerUpcomingRes.data,
      payerInvoices: payerInvoicesRes.data ?? [],
      subPartners: subPartnersRes.data ?? [],
    },
    loading
  ] as const;
}
