import AppSecured from "../../AppSecured";
import Typography from "@mui/material/Typography";
import { Add, CasesOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import Box from "@mui/material/Box";
import { Fab } from "@mui/material";
import * as React from "react";
import { useBoolean } from "../../../../assets/utils/utils";
import { SubPartnerList } from "./SubPartnerList";
import { CreateSubPartnerDialog } from "./CreateSubPartnerDialog";

const SubPartners = () => {
  const [ open, { setTrue, setFalse } ] = useBoolean();
  return <AppSecured>
    <header>
      <Typography variant={'h4'} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Box display={"flex"} alignItems={"center"}>
          <CasesOutlined fontSize={'large'} sx={{ mr: 1 }}/>
          <FormattedMessage id={"commercials"}/>
        </Box>
        <Fab
          color={"primary"}
          variant={"extended"}
          sx={{ boxShadow: "none" }}
          onClick={setTrue}
        >
          <Add sx={{ mr: 1 }}/>
          <FormattedMessage id={"create-commercial"}/>
        </Fab>
      </Typography>
    </header>

    <SubPartnerList/>
    <CreateSubPartnerDialog open={open} handleClose={setFalse}/>
  </AppSecured>
}

export default SubPartners;
