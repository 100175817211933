import authApi, { RefreshBody } from "../../../app/services/auth";
import { useEffect } from "react";
import Urls from "../../../app/appUrls";
import { useParams } from "react-router-dom";
import SimpleForm from "../../../assets/utils/SimpleForm";
import { useNotifications } from "../../../assets/utils/utils";
import AppPublic from "../AppPublic";
import Box from "@mui/material/Box";
import { LoadingButton } from "../../secured/Misc/Misc";
import { Login as LogIcon, RefreshOutlined } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import { PasswordsForm } from "../../secured/App/Profile/PasswordsForm";

const Refresh = () => {
  const { token } = useParams<{ token: string; }>();
  const [ refresh, refreshRes ] = authApi.usePasswordRefreshMutation();
  useNotifications(refreshRes, "success-refresh-password");
  useEffect(() => {
    if (refreshRes.isSuccess) {
      setTimeout(() => {
        window.location.href = Urls.login
      }, 1000);
    }
  }, [ refreshRes.isSuccess ])
  return <AppPublic>
    <SimpleForm onSubmit={(s) => refresh({ ...s, token } as RefreshBody)}>
      <PasswordsForm current={false} direction={"column"}/>
      <Box mt={1} pt={1}>
        <LoadingButton
          fullWidth
          type={'submit'}
          endIcon={<RefreshOutlined/>}
          size={'large'}
          data={refreshRes}
        >
          <FormattedMessage id={"refresh-password"}/>
        </LoadingButton>
      </Box>
    </SimpleForm>
  </AppPublic>
}

export default Refresh;
