import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import authApi from "../../../app/services/auth";
import { useAppDispatch } from "../../../app/hooks/hooks";
import { addNotification } from "../../../app/slices/notifications";
import Urls from "../../../app/appUrls";
import { redirect } from "../../../app/slices/app";
import { pushGAEvent } from "../../secured/Misc/Misc";

const Validate = () => {

  const params = useParams();
  const token = params.token;

  const dispatch = useAppDispatch();
  const [ verif, verifRes ] = authApi.useValidateMutation();

  useEffect(() => {
    if (token && verifRes.isUninitialized) {
      verif({ token });
    } else if (verifRes.isSuccess) {
      pushGAEvent("signup-partner");
      dispatch(addNotification({ color: "success", message: "account-validated" }));
      dispatch(redirect(Urls.login));
    }
  }, [ token, verifRes ]);

  return <React.Fragment/>
}

export default Validate;
