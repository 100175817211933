import globalApi from "../../../../app/services/globalApi";
import { useNotifications } from "../../../../assets/utils/utils";
import * as React from "react";
import { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, FormControl, TextField } from "@mui/material";
import { PersonAdd } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import Grid2 from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import { LoadingButton } from "../../Misc/Misc";
import { Commercial } from "../../../../app/services/customerApi";

type CreateSubPartnerDialogProps = {
  open: boolean,
  handleClose: () => void,
}
export const CreateSubPartnerDialog = (p: CreateSubPartnerDialogProps) => {

  const [ createCommercial, createCommercialRes ] = globalApi.useCreateCommercialMutation();
  useNotifications(createCommercialRes, "success-commercial-creation");
  useEffect(() => {
    createCommercialRes.isSuccess && p.handleClose();
  }, [ createCommercialRes.isSuccess ]);

  return (
    <Dialog open={p.open} onClose={p.handleClose} fullWidth>
      <DialogTitle display={"flex"} alignItems={"center"}>
        <PersonAdd/>
        <Typography variant={'h6'} ml={1}><FormattedMessage id={"new-commercial"}/></Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContent>
          <SimpleForm onSubmit={(s) => createCommercial(s as Commercial)}>
            <Grid2 container spacing={2}>
              <Grid2 xs>
                <FormControl fullWidth>
                  <TextField
                    size={'small'}
                    label={<FormattedMessage id={"first-name"}/>}
                    name={"first_name"}
                    required
                  />
                </FormControl>
              </Grid2>
              <Grid2 xs>
                <FormControl fullWidth>
                  <TextField
                    size={'small'}
                    label={<FormattedMessage id={"last-name"}/>}
                    name={"last_name"}
                    required
                  />
                </FormControl>
              </Grid2>
            </Grid2>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                size={'small'}
                label={"Email"}
                name={"authentication[email]"}
                required
              />
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <TextField
                size={'small'}
                label={<FormattedMessage id={"phone"}/>}
                name={"phone]"}
                required
              />
            </FormControl>
            <Box display={"flex"} justifyContent={"end"} mt={1}>
              <LoadingButton
                type={"submit"}
                data={createCommercialRes}
              >
                <FormattedMessage id={"create"}/>
              </LoadingButton>
            </Box>
          </SimpleForm>
        </DialogContent>
      </DialogContent>
    </Dialog>
  )
}
