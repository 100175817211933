import Box from "@mui/material/Box";
import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage } from "react-intl";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { CopyAll } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { addNotification } from "../../../../app/slices/notifications";
import { useAppDispatch } from "../../../../app/hooks/hooks";
import { useData } from "../../../../app/hooks/useData";

export const SubPartnerList = () => {
  const [ { subPartners } ] = useData();
  const dispatch = useAppDispatch();
  return <Box mt={1}>
    <FTable
      head={<>
        <TableCell><FormattedMessage id={"name"}/></TableCell>
        <TableCell>Email</TableCell>
        <TableCell/>
      </>}
    >
      {subPartners.map(c => <TableRow key={`c-${c.id}`}>
        <TableCell>{c.first_name + " " + c.last_name}</TableCell>
        <TableCell>{c.authentication.email}</TableCell>
        <TableCell>
          <Box display={"flex"} justifyContent={"end"}>
            <Tooltip
              placement={"top"}
              title={<FormattedMessage id={"copy-subpartner-code"}/>}
            >
              <IconButton
                size={'small'}
                onClick={() => {
                  const affiliationUrl = `https://app.feedcast.io/register/${c.authentication.affiliation_code}.html`;
                  navigator.clipboard.writeText(affiliationUrl);
                  dispatch(addNotification({
                    color: 'info',
                    message: "copied-in-clipboard"
                  }))
                }}
              >
                <CopyAll fontSize={"small"}/>
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>)}
    </FTable>
  </Box>
}
