import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { httpErrorMiddleware } from "./middlewares/errors";
import publicApi from "./services/publicApi";

import appReducer from './slices/app';
import notificationsReducer from './slices/notifications';

import securedApi from "./services/securedApi";

export const store = configureStore({
  reducer: {
    app: appReducer,
    notifications: notificationsReducer,
    [publicApi.reducerPath]: publicApi.reducer,
    [securedApi.reducerPath]: securedApi.reducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
    httpErrorMiddleware,
    publicApi.middleware,
    securedApi.middleware,
  ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;
