import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage, FormattedNumber } from "react-intl";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Alert, AlertTitle,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import {
  formatToLocaleDate,
  getFeedSubStatus,
  SubStatusColor,
  sumReducer,
  useNotifications
} from "../../../../assets/utils/utils";
import Avatar from "@mui/material/Avatar";
import googleAds from "../../../../assets/img/gads.svg";
import merchantCenter from "../../../../assets/img/merchant.svg";
import meta from "../../../../assets/img/meta.svg";
import {
  CardMembership,
  Edit,
  ExpandMore,
  FolderOffOutlined,
  Storefront,
  StorefrontOutlined
} from "@mui/icons-material";
import Vue from "../../Misc/Vue";
import { ResellerTypes } from "../../../../app/services/profile";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import Urls from "../../../../app/appUrls";
import * as React from "react";
import { useEffect, useState } from "react";
import { LoadingBlock, LoadingButton, LocaleFlag, niceUrl } from "../../Misc/Misc";
import Box from "@mui/material/Box";
import AutologinPopover from "../AutologinPopover";
import { useData } from "../../../../app/hooks/useData";
import customerApi, { Price, SubscriptionPayload } from "../../../../app/services/customerApi";
import Button from "@mui/material/Button";

type DashReportingProps = {
  merchant_id?: number | string,
}

const DashReporting = (p: DashReportingProps) => {
  const [ { feeds } ] = useData();
  const feedList = feeds.filter(f => p.merchant_id ? f.merchant.id === p.merchant_id : true) ?? [];

  const [ subFeed, setSubFeed ] = useState(0);
  const [ show, setShow ] = useState(false);

  return <Box>
    {!feedList.length
      ? <NoData />
      : (
        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMore fontSize={"large"} />}>
            <Stack direction={"row"} alignItems={"center"}>
              <Storefront sx={{ mr: 1 }}/>
              <Typography m={0} lineHeight={1}>
                <FormattedMessage id={"your-affiliates"}/>
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <FTable
              size={'small'}
              className={'table-text-end'}
              head={<>
                <TableCell><FormattedMessage id={"feed"}/></TableCell>
                {/*<TableCell><FormattedMessage id={"creation-date"}/></TableCell>*/}
                <TableCell><FormattedMessage id={"date-end-subscription"}/></TableCell>
                <TableCell><FormattedMessage id={"plan-type"}/></TableCell>
                <TableCell><FormattedMessage id={"accounts-connected"}/></TableCell>
                <TableCell><FormattedMessage id={"media-spent"}/></TableCell>
                <TableCell><FormattedMessage id={"nb-products"}/></TableCell>
                <TableCell style={{width:'15%'}}/>
              </>}
            >
              {feedList.map(f => <TableRow key={`f-${f.id}`}>
                <TableCell>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
                    <Typography lineHeight={1} variant={"caption"}>
                      {niceUrl(f.name)}
                    </Typography>
                    <LocaleFlag locale={f.locale}/>
                  </Box>
                </TableCell>
                {/*<TableCell>*/}
                {/*  {formatToLocaleDate(f.created_at, true)}*/}
                {/*</TableCell>*/}
                <TableCell>
                  {f.current_subscription && formatToLocaleDate(f.current_subscription?.date_end_subscription, true)}
                </TableCell>
                <TableCell>
                  <Chip
                    label={<FormattedMessage id={getFeedSubStatus(f)}/>}
                    color={SubStatusColor[getFeedSubStatus(f)] ?? "primary"}
                    variant={"outlined"}
                    size={'small'}
                  />
                </TableCell>
                <TableCell>
                  <Stack justifyContent={"end"} direction={"row"} spacing={1}>
                    {f.google?.ads?.name.length && <Avatar src={googleAds} sx={{ width: 24, height: 24 }}/>}
                    {f.google?.merchant?.name.length && <Avatar src={merchantCenter} sx={{ width: 24, height: 24 }}/>}
                    {f.facebook?.account?.name.length && <Avatar src={meta} sx={{ width: 24, height: 24 }}/>}
                  </Stack>
                </TableCell>
                <TableCell>
                  {<FormattedNumber value={(f.metrics?.spent || 0) / 100 ?? 0} currency={"eur"} style={"currency"}/>}
                </TableCell>
                <TableCell>
                  {f.last_stat?.imported ?? <FolderOffOutlined fontSize={"small"}/>}
                </TableCell>
                <TableCell>
                  {!p.merchant_id && (<Vue
                    show={[ ResellerTypes["NON_PAYER"], ResellerTypes["PAYER"], ResellerTypes.SUB_PARTNER ]}
                  >
                    <Tooltip
                      placement={"top"}
                      title={<FormattedMessage id={"edit-value"} values={{ merchant: f.merchant.name }}/>}
                    >
                      <IconButton
                        edge={"end"}
                        size={'small'}
                        component={Link}
                        to={Urls.app.customerEdit(f.merchant.id)}
                      >
                        <Edit fontSize={'small'}/>
                      </IconButton>
                    </Tooltip>
                    <AutologinPopover merchant={f.merchant.id}/>
                  </Vue>)}
                  <Vue show={[ ResellerTypes["PAYER"] ]}>
                    <Tooltip
                      placement={"top"}
                      title={<FormattedMessage id={"handle-subscription"}/>}
                    >
                      <IconButton
                        edge={"end"}
                        size={"small"}
                        onClick={() => {
                          setSubFeed(f.id);
                          setShow(true);
                        }}
                      >
                        <StorefrontOutlined/>
                      </IconButton>
                    </Tooltip>
                  </Vue>
                </TableCell>
              </TableRow>)}
            </FTable>
          </AccordionDetails>
        </Accordion>
      )
    }
    <ModalSubscription
      open={show}
      feed={subFeed}
      handleClose={() => setShow(false)}
    />
  </Box>
}

const NoData = () => {
  return (
    <Box>
      <Alert
        severity={"info"}
      >
        <FormattedMessage id={"no-shops"} />
        {/*<FormattedMessage id={"no-data-desc"} /> <br />*/}
        {/*<FormattedMessage id={"no-data-desc-2"} />*/}
      </Alert>
    </Box>
  )
}

type ModalSubscriptionProps = {
  feed: string | number,
  open: boolean,
  handleClose: () => void,
}

type PriceHandle = Pick<Price, "id" | "price" | "type">;

const ModalSubscription = (p: ModalSubscriptionProps) => {
  const { feed, handleClose } = p;

  const [ selected, setSelected ] = useState<PriceHandle[]>([]);
  const [ askCancel, setAskCancel ] = useState(false);

  const [ getPlans, plans ] = customerApi.useLazyGetCustomerPlansQuery();
  const [ initSubs, initSubsRes ] = customerApi.useInitMerchantSubscriptionMutation();
  const [ updateSubs, updateSubRes ] = customerApi.useUpdateMerchantSubscriptionsMutation();

  useNotifications(initSubsRes, "success-init-sub");
  useNotifications(updateSubRes, "success-update-sub");

  const isNew = plans.data?.is_new;
  const prices = plans.data?.prices ?? [];
  const packs = plans.data?.prices.filter(p => p.type === "pack") ?? [];
  const limits = plans.data?.prices.filter(p => p.type === "limits") ?? [];

  const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const p_id = parseInt(e.target.value);
    const found = prices.find(f => f.id === p_id);
    if (found) {
      const newSelection = [ ...selected ].filter(s => s.type !== found.type);
      newSelection.push({
        id: found.id,
        price: found.price,
        type: found.type,
      });
      setSelected(newSelection);
    }
  }

  useEffect(() => {
    if (p.open) {
      getPlans(feed);
    }
  }, [ p.open ]);

  useEffect(() => {
    if (plans.isSuccess) {
      setSelected(plans.data.prices.filter(p => p.selected).map(p => {
        return { id: p.id, price: p.price, type: p.type }
      }));
    }
  }, [ plans ]);

  const submit = () => {
    const body: SubscriptionPayload = {
      id: feed.toString(),
      data: {
        feed: feed,
        prices: selected.map(p => p.id),
      }
    }
    isNew ? initSubs(body) : updateSubs(body);
  }

  useEffect(() => {
    if (initSubsRes.isSuccess) {
      handleClose();
    } else if (updateSubRes.isSuccess) {
      handleClose();
    }
  }, [ initSubsRes, updateSubRes ]);

  return <React.Fragment>
    <Dialog
      open={p.open}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle display={"flex"} alignItems={"center"}>
        <CardMembership sx={{ mr: 1 }}/>
        <FormattedMessage id={"handle-subscription"}/>
      </DialogTitle>
      <LoadingBlock data={plans} key={selected.length}>
        <DialogContent>
          <Typography variant={"h6"}>Packs</Typography>
          <RadioGroup
            sx={{ px: 3 }}
            value={selected.filter(f => f.type === "pack")[0]?.id}
            onChange={handleSelection}
            key={packs.length}
          >
            {packs.map(p => <FormControlLabel
              value={p.id}
              control={<Radio/>}
              label={<Box display={"flex"}>
                <Typography><FormattedMessage id={p.code}/></Typography>
                <Box mx={1}>|</Box>
                <Typography>{p.max_products} <FormattedMessage id={"products"}/></Typography>
                <Box mx={1}>|</Box>
                <Typography><FormattedNumber value={p.price} style={"currency"} currency={"eur"}/></Typography>
              </Box>}
            />)}
          </RadioGroup>
          <Typography variant={"h6"}>Limites</Typography>
          <FormControl fullWidth>
            <RadioGroup
              sx={{ px: 3 }}
              value={selected.filter(f => f.type === "limits")[0]?.id}
              onChange={handleSelection}
              key={limits.length}
            >
              {limits.map(l => <FormControlLabel
                value={l.id}
                control={<Radio/>}
                label={<Box display={"flex"}>
                  <Typography><FormattedMessage id={l.code}/></Typography>
                  <Box mx={1}>|</Box>
                  <Typography>{l.max_products} <FormattedMessage id={"products"}/></Typography>
                  <Box mx={1}>|</Box>
                  <Typography><FormattedNumber value={l.price} style={"currency"} currency={"eur"}/></Typography>
                </Box>}
              />)}
            </RadioGroup>
          </FormControl>
          <Box px={2} display={"flex"} justifyContent={"end"} alignItems={"center"}>
            <Typography display={"flex"}>
              Total
              <Box mx={2}>|</Box>
            </Typography>
            <Typography variant={'h6'}>
              <FormattedNumber
                value={selected.map(s => s.price).reduce(sumReducer, 0)}
                style={"currency"}
                currency={"eur"}/>
            </Typography>
          </Box>
          <Stack px={2} mt={1} display={"flex"} justifyContent={"end"} direction={"row"}>
            <Button onClick={handleClose}>
              <FormattedMessage id={"cancel"}/>
            </Button>
            {!isNew && (
              <Button
                variant={"outlined"}
                color={"error"}
                sx={{ mx: 1 }}
                onClick={() => setAskCancel(true)}
              >
                <FormattedMessage id={"cancel-subscription"}/>
              </Button>
            )}
            <LoadingButton
              data={isNew ? initSubsRes : updateSubRes}
              variant={"contained"}
              onClick={() => submit()}
              sx={{ px: 5 }}
            >
              <FormattedMessage id={"validate"}/>
            </LoadingButton>
          </Stack>
        </DialogContent>
      </LoadingBlock>
    </Dialog>
    <DialogCancelSub
      feed={feed}
      open={askCancel}
      handleClose={() => setAskCancel(false)}
      onSuccess={() => {
        setAskCancel(false);
        handleClose();
      }}
    />
  </React.Fragment>
}

type DialogCancelSubProps = {
  open: boolean,
  handleClose: () => void,
  onSuccess: () => void,
  feed: string | number,
}

const DialogCancelSub = (p: DialogCancelSubProps) => {
  const { open } = p;
  const [ cancel, cancelRes ] = customerApi.useCancelMerchantSubscriptionMutation();
  useNotifications(cancelRes, "success-cancel-sub");
  useEffect(() => {
    cancelRes.isSuccess && p.onSuccess();
  }, [ cancelRes ])
  return <Dialog open={open} onClose={p.handleClose}>
    <DialogTitle><FormattedMessage id={"cancel-subscription"}/></DialogTitle>
    <DialogContent>
      <Typography>
        <FormattedMessage id={"cancel-subscription-ask"}/>
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={p.handleClose}>
        <FormattedMessage id={"cancel"}/>
      </Button>
      <LoadingButton
        color={"error"}
        data={cancelRes}
        onClick={() => cancel(p.feed)}
      >
        <FormattedMessage id={"confirm-cancel-sub"}/>
      </LoadingButton>
    </DialogActions>
  </Dialog>
}

export default DashReporting;
