import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import globalApi, { ChartAggregations } from "../../../../app/services/globalApi";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/hooks";
import { getReportingParams, setReportingParams } from "../../../../app/slices/app";
import { formatToLocaleDate } from "../../../../assets/utils/utils";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { StackedBarChart } from "@mui/icons-material";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { FormattedMessage } from "react-intl";

ChartJS.register(
  CategoryScale,
  LinearScale,
  // BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const registrationsOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: '',
    },
  },
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        display: false
      },
      ticks: {
        precision: 0
      }
    }
  }
};

const DashCharts = () => {

  const rParams = useAppSelector(getReportingParams);
  const periodsQuery = globalApi.useGetPeriodsQuery(rParams);
  const periods = periodsQuery.data ?? [];
  const labels = periods?.map(p => formatToLocaleDate(p.from, true)) ?? [];
  const registrations = periods.map((p) => p.registrations + p.registrations_linked + p.registrations_linked_subscriptions);
  // const hasRegistrations = registrations.length > 0;
  const subscriptions = periods.map(p => (p.reseller_premium + p.reseller_autopilot)).filter(p => p);
  // const hasSubscriptions = subscriptions.length > 0;

  const registrationsData = {
    labels,
    datasets: [
      {
        label: 'Utilisateurs',
        data: registrations,
        borderColor: 'rgba(104,99,255,0.5)',
        backgroundColor:'rgba(104,99,255,1)',
      },
      {
        label: 'Abonnements',
        data: periods.map((p) => (p.reseller_premium + p.reseller_autopilot)),
        borderColor: 'rgba(12,189,0,0.5)',
        backgroundColor: 'rgba(12,189,0,1)',
      },
    ],
  };

  return <Box mt={2}>
    <Paper>
      <Box p={2}>
        <Line
          options={registrationsOptions}
          data={registrationsData}
          height={"100px"}
        />
        <Box
          display={"flex"}
          justifyContent={"center"}
          mt={2}
        >
          <ChartViewToggle/>
        </Box>
      </Box>
    </Paper>
  </Box>
}

const ChartViewToggle = () => {
  const rParams = useAppSelector(getReportingParams);
  const dispatch = useAppDispatch();
  const setPeriod = (period: ChartAggregations) => dispatch(setReportingParams({ ...rParams, period }));
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
    >
      <StackedBarChart/>
      <ToggleButtonGroup
        exclusive
        value={rParams.period}
        size={'small'}
        onChange={(e, period: ChartAggregations) => setPeriod(period)}
        sx={{ml: 2}}
      >
        <ToggleButton value={ChartAggregations.Weekly}>
          <FormattedMessage id={"weekly"}/>
        </ToggleButton>
        <ToggleButton value={ChartAggregations.Monthly}>
          <FormattedMessage id={"monthly"}/>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default DashCharts;
