import { useCallback, useLayoutEffect } from "react";
import { Crisp } from "crisp-sdk-web";
import { useAppSelector } from "./app/hooks/hooks";

const key = process.env.REACT_APP_CRISP_KEY;

const ChatBot = () => {
  // const data = useAppSelector(getProfile)
  Crisp.configure(key as string, {
    autoload: false,
  });

  const crispInit = useCallback(() => {
    Crisp.load();
    // if (data) {
    //   Crisp.setTokenId(data.token);
    //   Crisp.user.setEmail(data.email);
    //   Crisp.session.setData({
    //     type: data.is_non_payer ? "Affilié (non payeur)" : "Payeur"
    //   });
    //   Crisp.session.setSegments([ data.role === "reseller" ? "partner" : "commercial" ], false);
    // }
  }, [ ]);

  useLayoutEffect(() => {
    crispInit();
  });

  return <div></div>;
}

export default ChatBot;
