import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

type CollapsibleTableProps = {
  head: any,
  children: any,
  size?: "small",
  className?: string,
}

export default function FTable(p: CollapsibleTableProps) {
  return (
    <TableContainer>
      <Table
        size={p.size ?? "medium"}
        className={p.className}
      >
        <TableHead>
          <TableRow>
            {p.head}
          </TableRow>
        </TableHead>
        <TableBody>
          {p.children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
