import profileApi, { NewPasswordBody } from "../../../../app/services/profile";
import { useNotifications } from "../../../../assets/utils/utils";
import React, { useEffect } from "react";
import { StorageManager } from "../../../../app/storage";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { PasswordsForm } from "./PasswordsForm";
import { LoadingButton } from "../../Misc/Misc";

export const Passwords = () => {
  const [ newPass, newPassRes ] = profileApi.useSetNewPasswordMutation();
  useNotifications(newPassRes, "success-edit-passwords");
  useEffect(() => {
    if (newPassRes.isSuccess) {
      StorageManager.clear();
      window.location.reload();
    }
  }, [ newPassRes.isSuccess ]);

  return <>
    <Typography variant={'h5'} mt={3} mb={1}>
      <FormattedMessage id={"passwords"}/>
    </Typography>
    <SimpleForm onSubmit={(s) => newPass(s as NewPasswordBody)}>
      <Paper sx={{p: 2}}>
        <PasswordsForm/>
        <Box mt={1} display={"flex"} justifyContent={"end"}>
          <LoadingButton
            data={newPassRes}
            variant={"contained"}
            type={"submit"}
          >
            <FormattedMessage id={"edit"}/>
          </LoadingButton>
        </Box>
      </Paper>
    </SimpleForm>
  </>
}
