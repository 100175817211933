import Typography from "@mui/material/Typography";
import AppSecured from "../../AppSecured";
import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import { FormattedMessage, FormattedNumber } from "react-intl";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import RangeCalendar from "../../Misc/RangeCalendar";
import { formatToLocaleDate, sumReducer } from "../../../../assets/utils/utils";
import { Badge, CancelRounded, CheckCircle, Handshake } from "@mui/icons-material";
import { ResellerTypes } from "../../../../app/services/profile";
import invoiceApi, { CommissionType } from "../../../../app/services/invoiceApi";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import { LoadingButton, niceUrl } from "../../Misc/Misc";
import { useData } from "../../../../app/hooks/useData";
import Paper from "@mui/material/Paper";
import { Chip } from "@mui/material";

/**
 * Commissions.tsx
 * Restricted to Non payer w/ commissions
 * Displays the current commissions stacking
 */

const Commissions = () => {
  return <AppSecured restrict={[ResellerTypes["NON_PAYER_COM"]]}>
    <Box
      width={"100%"}
      display={"flex"}
      justifyContent={"space-between"}
      mb={2}
    >
      <Typography variant={"h4"} display={"flex"} alignItems={"center"}>
        <Handshake fontSize={"large"} sx={{ mr: 1 }} />
        <FormattedMessage id={"commissions"} />
      </Typography>
      <RangeCalendar />
    </Box>
    <Paper sx={{ overflow: "hidden" }}>
      <Box>
        <CashbackTable />
      </Box>
    </Paper>

    {/*<ClaimCommissions/>*/}

  </AppSecured>
}

type FilteredCommission = {
  name: string,
  date: string,
  dateNext: string,
  subAmount: number,
  hasNewSub: boolean,
  prime: number,
  commission: number,
}

const CashbackTable = () => {
  const [{ commissions }] = useData();
  const [comAlt, setComAlt] = useState<FilteredCommission[]>([]);

  useEffect(() => {
    if (commissions.length) {
      const alt: FilteredCommission[] = commissions.map(c => ({
        name: c.feed?.name ?? "",
        date: formatToLocaleDate(c.date, true),
        dateNext: c.invoice.subscription?.date_end_period ? formatToLocaleDate(c.invoice.subscription.date_end_period, true) : "",
        subAmount: c.invoice.amount,
        hasNewSub: c.type === CommissionType["Welcome commission"],
        prime: c.type === CommissionType["Welcome commission"] ? c.amount : 0,
        commission: c.type === CommissionType["Invoice commission"] ? c.amount : 0,
      }));
      setComAlt(alt);
    }
  }, [commissions.length]);

  return <>
    <FTable
      className={"table-text-end"}
      /* size={"small"} */
      head={<>
        <TableCell><FormattedMessage id={"feed"} /></TableCell>
        <TableCell>Date commission</TableCell>
        {/* <TableCell><FormattedMessage id={"date-next-cashback"} /></TableCell> */}
        <TableCell><FormattedMessage id={"gains"} /></TableCell>
        {/* <TableCell><FormattedMessage id={"new-subscriptions"} /></TableCell> */}
        {/* <TableCell><FormattedMessage id={"prime-new-subs"} /></TableCell>
        <TableCell><FormattedMessage id={"commission-gains"} /></TableCell> */}
        <TableCell><FormattedMessage id={"total-gains"} /></TableCell>
      </>}
    >
      {comAlt.map((f, index) => <TableRow key={index}>
        <TableCell>{niceUrl(f.name)}</TableCell>
        <TableCell>{f.date}</TableCell>
        {/* <TableCell>{f.dateNext}</TableCell> */}
        <TableCell><FormattedNumber value={f.subAmount} style={"currency"} currency={"eur"} /></TableCell>
        {/* <TableCell>{f.hasNewSub ? <CheckCircle color={"success"} /> : <CancelRounded color={"warning"} />}</TableCell> */}
        {/* <TableCell><FormattedNumber value={f.prime} style={"currency"} currency={"eur"} /></TableCell>
        <TableCell><FormattedNumber value={f.commission} style={"currency"} currency={"eur"} /></TableCell> */}
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "end", gap: 1 }}>
            {f.hasNewSub && <Chip color="success" size="small" label="Nouvel abonnement" />}
            <FormattedNumber value={f.prime + f.commission} style={"currency"} currency={"eur"} />
          </Box>
        </TableCell>
      </TableRow>)}
      <TableRow sx={{
        backgroundColor: "#06053d",
        "& > td": { color: "#FAFAFA" }
      }}>
        <TableCell colSpan={3}>Total</TableCell>
        {/* <TableCell>
          <FormattedNumber
            value={comAlt.map(c => c.subAmount).reduce(sumReducer, 0)}
            style={"currency"}
            currency={"eur"}
          />
        </TableCell> */}
        {/* <TableCell /> */}
        {/* <TableCell>
          <FormattedNumber
            value={comAlt.map(c => c.prime).reduce(sumReducer, 0)}
            style={"currency"}
            currency={"eur"}
          />
        </TableCell>
        <TableCell>
          <FormattedNumber
            value={comAlt.map(c => c.commission).reduce(sumReducer, 0)}
            style={"currency"}
            currency={"eur"}
          />
        </TableCell> */}
        <TableCell>
          <FormattedNumber
            value={comAlt.map(c => c.commission + c.prime).reduce(sumReducer, 0)}
            style={"currency"}
            currency={"eur"}
          />
        </TableCell>
      </TableRow>
    </FTable>
  </>
}

// Deprecated
const ClaimCommissions = () => {
  const [{ commissions }] = useData();
  const me = useAppSelector(getUser);
  const sumCom = commissions.map(c => c.amount).reduce(sumReducer, 0);
  const [claim, claimRes] = invoiceApi.useClaimCommissionsMutation();
  return <>
    <Box mt={2} display={"flex"} justifyContent={"end"}>
      <LoadingButton
        variant={"contained"}
        onClick={() => claim()}
        sx={{ px: 5 }}
        disabled={sumCom < (me?.commission?.min_invoice_call ?? 500)}
        data={claimRes}
      >
        <FormattedMessage id={"claim-gains"} />
      </LoadingButton>
    </Box>
  </>
}

export default Commissions;
