const Urls = {
  login: "/",
  register: "/register",
  forgot: '/refresh',
  refresh: (token:string) => `/refresh-password/${token}.html`,
  autologin: "/autologin",
  autologinCustomer: (token: string) => `https://app.feedcast.io/autologin?token=${token}`,
  validate: (token: string) => `/validate-token/${token}.html`,
  app: {
    dash: '/dash',
    commissions: '/dash/commissions',
    billedCommissions: '/dash/gains',
    invoices: "/dash/invoices",
    invoicesPayer: "/dash/invoices/payer",
    program: "/dash/program",
    profile: '/profile',
    customers: '/dash/customers',
    customerEdit: (id: string | number) => `/dash/customers/${id}`,
    commercials: '/dash/commercials',
  }
}

export default Urls;
