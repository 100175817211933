import SimpleForm from "../../../../assets/utils/SimpleForm";
import Box from "@mui/material/Box";
import { Checkbox, FormControl, FormControlLabel, TextField } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { StepProps } from "./Onboarding";
import { OnboardingData } from "../../../../app/services/profile";
import Typography from "@mui/material/Typography";

const   InvoiceInfo = (p: StepProps) => {
  return <Box key={`b-${p.data.bank_account?.account_number}`}>
    <SimpleForm onSubmit={(s) => p.onSubmit(s as Partial<OnboardingData>)}>
      <FormControl margin="normal" fullWidth>
        <TextField
          name="bank_account[account_number]"
          label={<FormattedMessage id="bank-account"/>}
          required
          variant={"standard"}
          defaultValue={p.data.bank_account?.account_number}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          name="bank_account[account_owner]"
          label={<FormattedMessage id="bank-owner"/>}
          required
          variant={"standard"}
          defaultValue={p.data.bank_account?.account_owner}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          name="bank_account[bank_code]"
          label={<FormattedMessage id="bank-code"/>}
          required
          variant={"standard"}
          defaultValue={p.data.bank_account?.bank_code}
        />
      </FormControl>
      <FormControl margin="normal" fullWidth>
        <TextField
          name="bank_account[bank_name]"
          label={<FormattedMessage id="bank-name"/>}
          required
          variant={"standard"}
          defaultValue={p.data.bank_account?.bank_name}
        />
      </FormControl>
      <FormControl margin={"normal"} fullWidth>
        <FormControlLabel control={<Checkbox required />} label={<Typography variant={"caption"}>
          <FormattedMessage id={"by-checking-this"} />
          <a href={"/cgv-feedcast-partner.pdf"} target={"_blank"}><FormattedMessage id={"cgv"} /></a>
        </Typography>
        } />
      </FormControl>
      {p.nav}
    </SimpleForm>
  </Box>
}

export default InvoiceInfo;
