import { useAppSelector } from "../../../../app/hooks/hooks";
import { getUser } from "../../../../app/slices/app";
import profileApi, { Profile as ProfileType, ResellerTypes } from "../../../../app/services/profile";
import { useNotifications } from "../../../../assets/utils/utils";
import Vue from "../../Misc/Vue";
import Typography from "@mui/material/Typography";
import { FormattedMessage } from "react-intl";
import SimpleForm from "../../../../assets/utils/SimpleForm";
import Paper from "@mui/material/Paper";
import Grid2 from "@mui/material/Unstable_Grid2";
import { FormControl, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { LoadingButton } from "../../Misc/Misc";
import React from "react";
import BusinessInfo from "../Onboarding/BusinessInfo";

export const Address = () => {
  const me = useAppSelector(getUser);
  const address = me?.address;

  // TODO

  const [ setProfile, setProfileRes ] = profileApi.useSetProfileMutation();
  useNotifications(setProfileRes, "success-edit-address");

  return <Vue show={[ ResellerTypes["PAYER"] ]}>
    <Typography variant={"h5"} mt={3} mb={1}>
      <FormattedMessage id={'invoice-info'}/>
    </Typography>

    <Paper sx={{p: 2}}>
      {address && (
        <BusinessInfo
          key={address.address}
          onSubmit={(s) => setProfile(s)}
          nav={<></>}
          data={{ address }}
          size={"small"}
        />
      )}
      <Box mt={1} display={"flex"} justifyContent={"end"}>
        <LoadingButton
          type={"submit"}
          variant={"contained"}
          data={setProfileRes}
        >
          <FormattedMessage id={"edit"}/>
        </LoadingButton>
      </Box>
    </Paper>

    {/*{address && (<Paper sx={{p: 2}}>*/}
    {/*    <SimpleForm onSubmit={(s) => setProfile(s as Partial<ProfileType>)}>*/}
    {/*      <Grid2 container spacing={2}>*/}
    {/*        <Grid2 xs={6}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"address"}/>}*/}
    {/*              defaultValue={address?.address}*/}
    {/*              name={"address[address]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={6}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"address2"}/>}*/}
    {/*              defaultValue={address?.address2}*/}
    {/*              name={"address[address2]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}

    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"city"}/>}*/}
    {/*              defaultValue={address?.city}*/}
    {/*              name={"address[city]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"zip"}/>}*/}
    {/*              defaultValue={address?.zip}*/}
    {/*              name={"address[zip]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"country"}/>}*/}
    {/*              defaultValue={address?.country}*/}
    {/*              name={"address[country]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}

    {/*        <Grid2 xs={6}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"vat-number"}/>}*/}
    {/*              defaultValue={address?.vat_number}*/}
    {/*              name={"address[vat_number]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={6}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"vat-type"}/>}*/}
    {/*              defaultValue={address?.vat_type}*/}
    {/*              name={"address[vat_type]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}

    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"siret"}/>}*/}
    {/*              defaultValue={address?.siret}*/}
    {/*              name={"address[siret]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"phone-number"}/>}*/}
    {/*              defaultValue={address?.phone_number}*/}
    {/*              name={"address[phone_number]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*        <Grid2 xs={4}>*/}
    {/*          <FormControl fullWidth>*/}
    {/*            <TextField*/}
    {/*              label={<FormattedMessage id={"invoice-email"}/>}*/}
    {/*              defaultValue={address?.invoice_email}*/}
    {/*              name={"address[invoice_email]"}*/}
    {/*            />*/}
    {/*          </FormControl>*/}
    {/*        </Grid2>*/}
    {/*      </Grid2>*/}

          {/*<Box mt={1} display={"flex"} justifyContent={"end"}>*/}
          {/*  <LoadingButton*/}
          {/*    type={"submit"}*/}
          {/*    variant={"contained"}*/}
          {/*    data={setProfileRes}*/}
          {/*  >*/}
          {/*    <FormattedMessage id={"edit"}/>*/}
          {/*  </LoadingButton>*/}
          {/*</Box>*/}
    {/*    </SimpleForm>*/}
    {/*  </Paper>*/}
    {/*)}*/}
  </Vue>
}
