import { useData } from "../../../../app/hooks/useData";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage, FormattedNumber } from "react-intl";
import FTable from "../../Misc/FTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { formatToLocaleDate } from "../../../../assets/utils/utils";
import { Chip, Paper } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { CloudDownloadOutlined } from "@mui/icons-material";

export const PayerInvoicesHistory = () => {
  const [ { payerInvoices } ] = useData();

  return (
    <Box mt={3}>
      <Typography variant={'subtitle1'} mb={1}><FormattedMessage id={"invoice-history"}/></Typography>
      <Paper sx={{ overflow: "hidden" }}>
        <FTable
          // className={"table-text-end"}
          size={'small'}
          head={<>
            <TableCell><FormattedMessage id={"code"}/></TableCell>
            <TableCell><FormattedMessage id={"amount"}/></TableCell>
            <TableCell><FormattedMessage id={"date-creation"}/></TableCell>
            <TableCell><FormattedMessage id={"status"}/></TableCell>
            <TableCell/>
          </>}
        >
          {payerInvoices.slice().sort((a, b) => b.created - a.created).map(p => <TableRow key={`p-${p}`}>
            <TableCell><Chip size={"small"} label={p.code} /></TableCell>
            <TableCell><FormattedNumber value={p.amount} style={"currency"} currency={"eur"}/></TableCell>
            <TableCell>{formatToLocaleDate(p.created, true)}</TableCell>
            <TableCell>
              <Chip
                size={'small'}
                label={p.status}
                color={p.status === "paid" ? "info" : "warning"}
              />
            </TableCell>
            <TableCell>
              <IconButton
                size={'small'}
                component={'a'}
                href={p.url}
                target={'_blank'}
              >
                <CloudDownloadOutlined fontSize={'small'}/>
              </IconButton>
            </TableCell>
          </TableRow>)}
        </FTable>
      </Paper>
    </Box>
  )
}
