import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link } from "react-router-dom";
import Urls from "../../../app/appUrls";

import logo from '../../../assets/img/partners-logo.png';
import profileApi, { ResellerTypes } from "../../../app/services/profile";
import { useAppSelector } from "../../../app/hooks/hooks";
import { getUser } from "../../../app/slices/app";
import { FormattedMessage } from "react-intl";
import { Link as LinkMUI, ListItemIcon } from "@mui/material";
import { AppsRounded, Dashboard, Group, People, Receipt, VerifiedUser } from "@mui/icons-material";

const pages = [
  {
    label: "Dashboard",
    link: Urls.app.dash,
    icon: <Dashboard fontSize={"small"}/>
  },
  {
    label: <FormattedMessage id={"commissions"}/>,
    link: Urls.app.commissions,
    restrict: [ ResellerTypes["NON_PAYER_COM"] ],
    icon: <Receipt fontSize={"small"}/>
  },
  {
    label: <FormattedMessage id={"invoicing"}/>,
    link: Urls.app.invoices,
    restrict: [ ResellerTypes["NON_PAYER_COM"] ],
    icon: <Receipt fontSize={"small"}/>
  },
  {
    label: <FormattedMessage id={"program"}/>,
    link: Urls.app.program,
    restrict: [ ResellerTypes["NON_PAYER_COM"] ],
    icon: <AppsRounded fontSize={"small"}/>
  },
  {
    label: <FormattedMessage id={"customers"}/>,
    link: Urls.app.customers,
    restrict: [ ResellerTypes.SUB_PARTNER, ResellerTypes["PAYER"], ResellerTypes["NON_PAYER"] ],
    icon: <Group fontSize={"small"}/>,
  },
  {
    label: <FormattedMessage id={"commercials"}/>,
    link: Urls.app.commercials,
    restrict: [ ResellerTypes["NON_PAYER"] ],
    icon: <People fontSize={"small"}/>,
  },
  {
    label: <FormattedMessage id={"invoicing"}/>,
    link: Urls.app.invoicesPayer,
    restrict: [ ResellerTypes["PAYER"] ],
    icon: <Receipt fontSize={"small"}/>,
  }
]

function ResponsiveAppBar() {
  const [ anchorElNav, setAnchorElNav ] = React.useState<null | HTMLElement>(null);
  const [ anchorElUser, setAnchorElUser ] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = useAppSelector(getUser);
  const [ logout, logoutRes ] = profileApi.useLogoutMutation();

  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: 'white',
        backgroundSize: '100%',
        borderRadius: 0,
        borderTop: "3px solid #614DFA",
        boxShadow: "0 1px 2px #D4DDE7, 0 4px 12px 0px #D4DDE7",
      }}
      sx={{
        mb: 2,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar sx={{ minHeight: "48px" }} disableGutters>
          <Link to={Urls.app.dash}>
            <img src={logo} height={"60px"}/>
          </Link>

          <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}/>
          <Box sx={{
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' },
            justifyContent: "start",
            ml: 3,
            gap: 4,
          }}>
            {pages
            .filter(p => p.restrict
              ? p.restrict.includes(user?.type as ResellerTypes)
              : true)
            .map((page) => {
              const isPage = page.link === window.location.pathname;
              return <LinkMUI
                key={page.link}
                onClick={handleCloseNavMenu}
                component={Link}
                to={page.link}
                sx={{
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemIcon sx={{ minWidth: '0', "& svg": { color: isPage ? "secondary.main" : undefined } }}>
                  {page.icon}
                </ListItemIcon>
                <Typography
                  sx={{
                    display: 'block',
                    // mx: 2,
                    ml: 1,
                  }}
                  color={isPage ? "secondary" : "primary"}
                >
                  {page.label}
                </Typography>
              </LinkMUI>
            })}
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: "flex"
            }}
          >
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar/>
            </IconButton>
            <Menu
              // sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem
                component={Link}
                to={Urls.app.profile}
              >
                <FormattedMessage id={"profile"}/>
              </MenuItem>
              <MenuItem onClick={() => logout()}>
                <FormattedMessage id={"logout"}/>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
