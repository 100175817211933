import * as React from "react";
import DashReporting from "./DashReporting";
import Box from "@mui/material/Box";
import DashCharts from "./DashCharts";

export const DashSummary = () => {
  return <Box>
    <DashReporting/>
    <DashCharts />
  </Box>
}

export default DashSummary;
