import securedApi from "./securedApi";
import ApiUrls from "../apiUrls";

const feedApi = securedApi.injectEndpoints({
  endpoints: build => ({
    getFeeds: build.query<MFeed[], FeedParams>({
      query: (params) => ({
        url: ApiUrls.feeds.list,
        params,
      }),
      providesTags: ["Feeds"]
    }),
  })
})

export default feedApi;

export type MFeed = {
  id: number,
  last_stat: null | { imported: number },
  name: string,
  url: null | string,
  locale: string,
  created_at: number,
  facebook: null | {
    account: null | {
      code: string,
      name: string
    },
  },
  google: null | {
    ads: null | {
      code: string,
      name: string
    },
    merchant: null | {
      code: string,
      name: string
    }
  },
  current_subscription: null | {
    date_start_subscription: number,
    date_end_subscription: number,
    date_end_trial: number | null,
    is_cancelled: boolean,
    plan: {
      plan: "starter" | "premium",
      limit: number,
    }
  },
  merchant: {
    id: number,
    name: string,
  },
  metrics?: {
    spent: number,
  }
}

export type FeedParams = {
  metrics_from: string,
  metrics_to: string,
}
