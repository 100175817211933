import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCustomHeaders } from "../apiUrls";

const securedApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, store) => getCustomHeaders(headers, true),
    baseUrl: process.env.REACT_APP_API_DOMAIN,
  }),
  tagTypes: [ "Me", "Profile", "Customers", "Customer", "Commercials", "GlobalCountries", "Feeds" ],
  endpoints: () => ({}),
});

export default securedApi;
