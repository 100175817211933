import Urls from "../../app/appUrls";
import ResponsiveAppBar from "./Misc/Nav";
import Box from "@mui/material/Box";
import { ResellerTypes } from "../../app/services/profile";
import { CircularProgress, Fade } from "@mui/material";
import { RtkApiRes } from "../../app/types/misc";
import { useAppSelector } from "../../app/hooks/hooks";
import { getUser } from "../../app/slices/app";
import { Navigate } from "react-router-dom";
import { useData } from "../../app/hooks/useData";
import { useIsLogged } from "../../app/hooks/useIsLogged";
import { useInitUser } from "../../app/hooks/useInitUser";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Onboarding from "./App/Onboarding/Onboarding";

type AppSecuredProps = {
  children: any,
  restrict?: ResellerTypes[],
  query?: RtkApiRes<any>
}

const AppSecured = (p: AppSecuredProps) => {
  useInitUser();

  const logged = useIsLogged();
  const me = useAppSelector(getUser);
  const [ {}, loading ] = useData();
  const need_onboarding = me?.need_onboarding ?? false;
  const isValid = (me && me.type === ResellerTypes.NON_PAYER_COM ? me?.validated : true);

  return <Box id={'app-secured'}>
    {!logged && <Navigate to={Urls.login}/>}
    {me !== null && (
      (need_onboarding || !isValid)
        ? <Onboarding/>
        : <Box>
          <ResponsiveAppBar/>
          <Container maxWidth={"xl"}>
            <Box width={'100%'}>
              <Fade in={true} appear>
                <Box py={1} mb={6}>
                  <Fade in={true} timeout={1000}>
                    <div>
                      {loading || !me || !me.type
                        ? <Box display={"flex"} justifyContent={"center"} height={'90vh'}><CircularProgress/></Box>
                        : (p.restrict?.includes(me.type) || !p.restrict)
                          ? <Box>{p.children}</Box>
                          : <Navigate to={Urls.app.dash}/>}
                    </div>
                  </Fade>
                </Box>
              </Fade>
            </Box>
          </Container>
        </Box>)}
  </Box>
}

export default AppSecured;
