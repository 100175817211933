import AppSecured from "../../AppSecured";
import Typography from "@mui/material/Typography";
import { SentimentVerySatisfied } from "@mui/icons-material";
import { FormattedMessage } from "react-intl";
import React from "react";
import { CommonProfile } from "./CommonProfile";
import { Address } from "./Address";
import { Passwords } from "./Passwords";

const Profile = () => {
  return <AppSecured>
    <Typography variant={"h4"} display={"flex"} alignItems={"center"} mb={1}>
      <SentimentVerySatisfied fontSize={"large"} sx={{ mr: 1 }}/>
      <FormattedMessage id={"profile"}/>
    </Typography>
    <CommonProfile/>
    <Passwords/>
    <Address/>
  </AppSecured>
}

export default Profile;
