import gradient2 from '../../assets/img/gradient_2.png';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { ReactNode } from "react";
import { Avatar, Grow } from "@mui/material";
import logo from "../../assets/img/partners-logo.png";

type AppPublicProps = {
  children: any,
  actions?: ReactNode,
}

const AppPublic = (p: AppPublicProps) => {
  return <Box
    id={"app-public"}
    style={{
      backgroundImage: `url(${gradient2})`,
      backgroundRepeat: "no-repeat",
    }}
    sx={{
      backgroundSize: {
        xs: "auto 100%",
      }
    }}
  >
    <Grow in>
      <Box
        id={"auth-form"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          mb={2}
        >
          <Grow in>
            <img src={logo} height={100} />
          </Grow>
        </Box>
        <Paper
          sx={{
            maxWidth: {
              xs: '90vw',
              sm: '40vw',
            },
            minHeight:'35vh',
            display:"flex",
            justifyContent:"center",
            flexDirection:"column"
          }}
        >
          <Box p={5} textAlign={"center"} height={'100%'}>
            {p.children}
          </Box>
        </Paper>
        {p.actions}
      </Box>
    </Grow>
  </Box>;
}

export default AppPublic;
