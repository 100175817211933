import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormattedMessage, FormattedNumber } from "react-intl";
import Paper from "@mui/material/Paper";
import customerApi, { MUser } from "../../../../app/services/customerApi";
import TableRow from "@mui/material/TableRow";
import FTable from "../../Misc/FTable";
import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { formatToLocaleDate, sumReducer } from "../../../../assets/utils/utils";
import { Group, GroupOutlined, HourglassDisabled } from "@mui/icons-material";
import Grid2 from "@mui/material/Unstable_Grid2";
import invoiceApi from "../../../../app/services/invoiceApi";

type CustomerUsersProps = {
  users: MUser[],
  merchant?: number,
}

const CustomerUsers = (p: CustomerUsersProps) => {
  const { users } = p;
  return <Box mt={2}>
    <Grid2 container spacing={2} alignItems={"end"}>
      <Grid2 xs>
        <Typography variant={"h5"} sx={{ display: "flex", alignItems: "center" }}>
          <GroupOutlined sx={{ mr: 1 }}/>
          <FormattedMessage id={"users"}/>
        </Typography>
        <Box>
          <Paper sx={{ overflow: "hidden" }}>
            <FTable
              size={"small"}
              head={<React.Fragment>
                <TableCell>Email</TableCell>
                <TableCell><FormattedMessage id={"first-name"}/></TableCell>
                <TableCell><FormattedMessage id={"last-name"}/></TableCell>
                <TableCell><FormattedMessage id={"phone-number"}/></TableCell>
                <TableCell><FormattedMessage id={"last-seen"}/></TableCell>
              </React.Fragment>}
            >
              {users.map(u => <User key={`u-${u.id}`} {...u} />)}
            </FTable>
          </Paper>

        </Box>
      </Grid2>
      {/*<Grid2 xs>*/}
      {/*  {p.merchant && <CustomerCards merchant={p.merchant}/>}*/}
      {/*</Grid2>*/}
    </Grid2>
  </Box>
}

const User = (u: MUser) => {
  return <TableRow>
    <TableCell>{u.authentication.email}</TableCell>
    <TableCell>{u.first_name}</TableCell>
    <TableCell>{u.last_name}</TableCell>
    <TableCell>{u.phone_number}</TableCell>
    <TableCell>
      {u.authentication.last_connection
        ? formatToLocaleDate(u.authentication.last_connection)
        : <HourglassDisabled fontSize={"small"}/>}
    </TableCell>
  </TableRow>
}

type CustomerCardsProps = {
  merchant: number,
}

const CustomerCards = (p: CustomerCardsProps) => {

  const [ getInvoices, invoicesQuery ] = invoiceApi.useLazyGetCustomerInvoicesQuery();
  const [ getCurrentSub, currentSubQuery ] = invoiceApi.useLazyGetCustomerCurrentSubQuery();

  const [ cumulInvoices, setCumulInvoices ] = useState(0);
  const [ cumulLast, setCumulLast ] = useState(0);

  useEffect(() => {
    if (p.merchant) {
      getInvoices(p.merchant, true);
      // getCurrentSub(p.merchant, true);
    }
  }, [ p.merchant ]);

  useEffect(() => {
    if (invoicesQuery.data?.length) {
      const cumulated = invoicesQuery.data?.filter(i => i.status === "paid").map(i => i.amount / 100).reduce(sumReducer, 0) ?? 0;
      setCumulInvoices(cumulated);
    }

    if (currentSubQuery.data) {
      // console.log(currentSubQuery.data);
      // const cumulated2 =
    }
  }, [ invoicesQuery.data, currentSubQuery.data ]);

  return <>
    <Paper>
      <Box p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant={'body2'}><FormattedMessage id={"cumulated-ca"}/></Typography>
        <Typography variant={"h5"} display={"flex"} justifyContent={"end"}>
          <FormattedNumber value={cumulInvoices} style={"currency"} currency={"eur"}/>
        </Typography>
      </Box>
    </Paper>
    <Paper sx={{ mt: 1 }}>
      <Box p={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant={'body2'}><FormattedMessage id={"subscription-amount"}/></Typography>
        <Typography variant={"h5"} display={"flex"} justifyContent={"end"}>
          {/*<FormattedNumber value={cumulInvoices} style={"currency"} currency={"eur"}/>*/}
          <Typography variant={"caption"}>TODO</Typography>
        </Typography>
      </Box>
    </Paper>
  </>
}

export default CustomerUsers;
